import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import BlankLayout from '@/layouts/BlankLayout'
import BGLayout from '@/layouts/BGLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: BGLayout,
    redirect: '/Dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
    ],
  },

  {
    path: '/eForm',
    name: 'eForm',
    component: BlankLayout,
    redirect: '/',
    children: [
      {
        path: 'form_application/:branch',
        name: 'Form_Application',
        meta: {
          permissions: 'customer_record_card_view',
        },
        component: () => import('@/views/eForm/Form_Application'),
      },
      {
        path: 'form_application/:branch/:id',
        name: 'Form_Application_History',
        meta: {
          permissions: 'customer_record_card_view',
        },
        component: () => import('@/views/eForm/Form_Application'),
      },
      /*
      {
        path: 'customer_record_card/view/:id',
        name: 'Nutritional_Assessment_Record',
        meta: {
          permissions: 'nutritional_assessment_view'
        },
        component: () =>
          import('@/views/eForm/CustomerRecordCard_ViewEdit'),
      },
      */
    ],
  },

  {
    path: '/auth',
    name: 'Auth',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    redirect: '/auth/login',
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
    ],
  },

  {
    path: '/booking',
    name: 'Booking',
    component: BlankLayout,
    redirect: '/',
    meta: {
      permissions: 'portal_report_view',
    },
    children: [
      {
        path: 'listing/:branch',
        name: 'Booking',
        component: () => import('@/views/booking/BookingList'),
      },
      {
        path: 'listing_m/:branch',
        name: 'BookingR',
        component: () => import('@/views/booking/BookingList_readonly'),
      },
      {
        path: 'followup/:branch',
        name: 'FollowUp',
        component: () => import('@/views/booking/FollowUp'),
      },
      {
        path: 'advisor/:branch',
        name: 'Advisor',
        component: () => import('@/views/booking/Advisor'),
      },
      {
        path: 'CallTeam',
        name: 'CallTeam',
        component: () => import('@/views/booking/CallTeam'),
      },
      {
        path: 'MemberBlockList',
        name: 'MemberBlockList',
        component: () => import('@/views/booking/MemberBlockList'),
      },
      {
        path: 'BookingListES',
        name: 'BookingListES',
        component: () => import('@/views/booking/BookingListES'),
      },
    ],
  },

  {
    path: '/setting',
    name: 'Setting',
    component: BlankLayout,
    redirect: '/',
    children: [
      {
        path: 'AssignRole',
        name: 'AssignRole',
        component: () => import('@/views/setting/AssignRole'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
