<template>
  <b-alert v-show="version_update" show variant="warning">有新的系統版本! 5秒後將重新載入</b-alert>
  <b-alert v-show="auto_update_msg" show variant="warning">資料同步中!!!</b-alert>
  <!-- Modal for Authorization-->
  <div>
    <b-button
      id="confirmModal_open"
      variant="outline-success"
      style="display: none"
      data-bs-toggle="modal"
      data-bs-target="#confirmModal"
      >{{ $t('common.button.confirm') }}</b-button
    >
  </div>
  <div
    id="confirmModal"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="confirmModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="confirmModalLabel" class="modal-title"></h5>
          <button
            id="confirmModal_close"
            type="button"
            class="btn-close"
            style="display: none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <span>{{ $t('common.master.pass_code') }}</span>
              <CFormInput
                id="staff_code"
                v-model="staff_code"
                size="sm"
                type="password"
                @keyup.enter="staff_code_check()"
              />
            </div>
          </div>
          <div v-if="!vaild_staff_code && !staff_id && allowed" class="row">
            <div class="col-12">
              <span class="text-danger">{{
                $t('common.message.staff_code_error')
              }}</span>
            </div>
          </div>
          <div v-if="!vaild_staff_code && staff_id">
            <div class="row">
              <div class="col-12">
                <span class="text-danger">{{
                  $t('common.message.permission_denied')
                }}</span>
              </div>
            </div>
          </div>
          <div v-if="!vaild_staff_code && !allowed" class="row">
            <div class="col-12">
              <h4 style="color: red;">請使用公司網絡!!!</h4>
            </div>
          </div>
          <div class="row" v-if="staff_code_checked == false">
            <div class="col-12">
              <!--
              <b-button
                variant="secondary"
                href="./#/Dashboard"
                @click="home_page()"
              >
                {{ $t('common.button.home') }}
              </b-button>
              -->
              輸入後按Enter/提交繼續
            </div>
          </div>
          <div>
            <b-button variant="outline-success" @click="staff_code_check()">{{
              $t('common.button.submit')
            }}</b-button>
          </div>
        </div>
        <div class="modal-footer" style="display: none">
          <b-button variant="outline-success" @click="submit()">{{
            $t('common.button.submit')
          }}</b-button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Authorization-->

  <div class="offcanvas offcanvas-start" tabindex="-1" id="without_therapistoffcanvas">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title text-center">房間沒有理療師</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body text-left">
      <div>
        <template v-for="item in without_therapist_list" :key="item.id">
          <div>
            <div class="row">
              <div class="col-8 text-left">
                <span>房號: {{ getlistitem('room', item.room) }}</span>
                <span v-show="item.without_therapist == 1" style="color:red;">&nbsp;&nbsp;未有理療師</span>
                <span v-show="item.without_therapist == 2" style="color:red;">&nbsp;&nbsp;有部份理療師</span>
              </div>
              <div class="col-4 text-right">
                <b-button
                  size="sm"
                  variant="outline-success"
                  @click="booking_click(item)"
                  >
                  <span>{{ $t('common.button.view') }}</span>
                </b-button>
              </div>
            </div>
            <div class="row">
              <span>
                客戶: {{ item.member_no }}<span v-show="!item.nickname">-{{ item.name }}</span><span v-show="item.nickname">-{{ item.nickname }}</span>
              </span>
            </div>
            <div class="row">
              <span>Check-in時間: {{ item.checkin_date }} {{ item.checkin_time }}</span>
            </div>
            <div class="row">
              <span>理療師: {{ getlistitem('therapist', item.org_therapist) }}</span>
            </div>
          </div>
          <hr>
        </template>
      </div>
    </div>
  </div>

  <b-overlay :show="show" rounded="sm">
    <div class="row">
      <div class="col-4 text-left">
        <b-button style="background-color: white !important; border-radius: 2rem !important; color:rgb(4, 97, 66) !important;" href="./#/Dashboard" class="mx-1" variant="outline-light">
          <font-awesome-icon :icon="['fas', 'house-user']" size="1x"/>
        </b-button>
        &nbsp;
        <b-button
          class="padonly"
          size="sm"
          variant="outline-secondary"
          data-bs-toggle="offcanvas"
          data-bs-target="#functionlistoffcanvas"
          >
            <span>功能表</span>
          </b-button
        >
      </div>
      <div class="col-4">
        <div class="flex-row justify-content-between">
          <div>
            <span>預約表 ({{ branch }})</span>
          </div>
        </div>
      </div>
      <div class="col-2 text-right">
        <b-button
          v-if="staff == 'all'"
          class="mx-1 position-relative padonly"
          variant="outline-secondary"
          @click="change_mode()"
          size="sm"
        >
          <span :class="followup_mode ? 'need_update' : ''">Follow-up</span>
        </b-button>
      </div>
      <div class="col-2 text-right">
        <span style="font-size: 10px;">V{{ version }}</span>
      </div>
    </div>

    <div id="fullscreen" class="bg-white">
      <!-- Modal for Event -->
      <div
        id="eventModal"
        class="modal fade"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-hidden="true"
      >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-center">
              <span>預約</span>
            </h5>
          </div>
          <div class="modal-body">
            <div v-if="eventInfo">
              <div v-if="eventInfo.special_event > 0">
                <div class="row">
                  <div class="col-3 text-left">
                  </div>
                  <div class="col-3 text-center">
                    <span>類型</span>
                  </div>
                  <div class="col-3 text-left">
                    <b>{{ getlistitem('special_event', eventInfo.special_event) }}</b>
                  </div>
                  <div class="col-3 text-left">
                  </div>
                </div>
                <div class="row">
                  <div class="col-3 text-left"></div>
                  <div class="col-3 text-center">
                    <span>備註</span>
                  </div>
                  <div class="col-6 text-left">
                    <b>{{ eventInfo.remark }}</b>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3 text-left">
                  </div>
                  <div class="col-3 text-center">
                    <span>開始時間</span>
                  </div>
                  <div class="col-3 text-left">
                    <b>{{ eventInfo.start_time }}</b>
                  </div>
                  <div class="col-3 text-left">
                  </div>
                </div>
                <div class="row">
                  <div class="col-3 text-left">
                  </div>
                  <div class="col-3 text-center">
                    <span>結束時間</span>
                  </div>
                  <div class="col-3 text-left">
                    <b>{{ eventInfo.end_time }}</b>
                  </div>
                  <div class="col-3 text-left">
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="row">
                  <div class="col-3 text-left">
                    <span>會員編號</span>
                  </div>
                  <div class="col-3 text-left">
                    <span>名稱</span>
                  </div>
                  <div class="col-3 text-left">
                    <span>別名</span>
                  </div>
                  <div class="col-3 text-left">
                    <span>性別</span>
                  </div>
                </div>
                <div>
                  <div class="row">
                    <div class="col-3 text-left">
                      <b>{{ eventInfo.member_no }}</b>
                    </div>
                    <div class="col-3 text-left">
                      <b>{{ eventInfo.name }}</b>
                    </div>
                    <div class="col-3 text-left">
                      <b>{{ eventInfo.nickname }}</b>
                    </div>
                    <div class="col-3 text-left">
                      <b>{{ eventInfo.gender }}</b>
                    </div>
                  </div>
                  <div class="row" v-show="eventInfo.block_list" :style="{ 'background-color': eventInfo.block_list_color}">
                    <div class="col-12 text-left">
                      <img :src="'./triangle_exclamation.svg'" width="30" height="25"/><b style="color:white; white-space:break-spaces;">{{ eventInfo.block_list_color_desc }}</b>
                      <br>
                      <b v-show="eventInfo.block_list_remark" style="color:white; white-space:break-spaces;">{{ eventInfo.block_list_remark }}</b>
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-4 text-left">
                      <span>預約日期</span>
                    </div>
                    <div class="col-4 text-left">
                      <span>開始時間</span>
                    </div>
                    <div class="col-4 text-left">
                      <span>結束時間</span>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-4 text-left">
                      <b>{{ eventInfo.bookingDate }}</b>
                    </div>
                    <div class="col-4 text-left">
                      <b>{{ eventInfo.start_time }}</b>
                    </div>
                    <div class="col-4 text-left">
                      <b>{{ eventInfo.end_time }}</b>
                    </div>
                  </div>
                  <div class="row" v-if="eventInfo.created_from != '1'">
                    <div class="col-6 text-left">
                      <span>Call Team備註: {{ eventInfo.remark_call }}</span>
                    </div>
                    <div class="col-6 text-left">
                      <span>種類: {{ getlistitem('member_type', eventInfo.member_type) }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-left">
                      <span>備註</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-left">
                      <b>{{ eventInfo.remark }}</b>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-left">
                      <span>內部備註 (不會顯示在電話版面)</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-left">
                      <b>{{ eventInfo.remark_internal }}</b>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3 text-left">
                      <span>理療師</span>
                    </div>
                    <div class="col-9 text-left">
                      <span></span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3 text-left">
                      <b>{{ getlistitem('therapist', eventInfo.org_therapist) }}</b>
                    </div>
                    <div class="col-3">
                      <b-form-checkbox
                        v-model="eventInfo.new_join"
                        value="1"
                        unchecked-value="0"
                        disabled="true"
                      >
                        <span>新會員</span>
                      </b-form-checkbox>
                    </div>
                    <div class="col-3">
                      <b-form-checkbox
                        v-model="eventInfo.specify"
                        value="1"
                        unchecked-value="0"
                        disabled="true"
                      >
                        <span>指定理療師</span>
                      </b-form-checkbox>
                    </div>
                    <div class="col-3">
                      <b-form-checkbox
                        v-model="eventInfo.recommend"
                        value="1"
                        unchecked-value="0"
                        disabled="true"
                      >
                        <span>推介理療師</span>
                      </b-form-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 text-left" v-if="eventAction == 'edit'">
                      <span>Re Confirm</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 text-left" v-if="eventAction == 'edit'">
                      <b-form-radio-group
                        v-model="eventInfo.reconfirm"
                        :options="reconfirm_list"
                        value-field="value"
                        text-field="name"
                        disabled="true"
                      ></b-form-radio-group>
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-4 text-left" v-if="eventAction == 'edit'">
                      <span>到達時間</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4 text-left" v-if="eventAction == 'edit'">
                      <b v-if="eventInfo.arrival_time">{{ eventInfo.arrival_time }}</b>
                      <b v-else>未到達</b>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4 text-left">
                      <span>營養師</span>
                    </div>
                    <div class="col-4 text-left">
                      <span>中醫師</span>
                    </div>
                    <div class="col-4 text-left">
                      <span>儀器機</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4 text-left">
                      <b>{{ getlistitem('nutritionist', eventInfo.nutritionist) }}</b>
                    </div>
                    <div class="col-4 text-left">
                      <b>{{ getlistitem('chinese_medicine', eventInfo.chinese_medicine) }}</b>
                    </div>
                    <div class="col-4 text-left">
                      <b>{{ getlistitem('machine', eventInfo.machine) }}</b>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4 text-left">
                      <span>運動科學顧問</span>
                    </div>
                    <div class="col-4 text-left">
                      <span>會員類別</span>
                    </div>
                    <div class="col-4 text-left">
                      <span></span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4 text-left">
                      <b>{{ getlistitem('sports_science_consultant', eventInfo.org_sports_science_consultant) }}</b>
                    </div>
                    <div class="col-4 text-left">
                      <b v-if="eventInfo.sports_science_type == 1">首次會員</b>
                      <b v-if="eventInfo.sports_science_type == 2">療程會員</b>
                    </div>
                    <div class="col-4 text-left">
                      <b></b>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4 text-left" v-if="eventInfo.checkin_time">
                      <span>Check-in時間</span>
                    </div>
                    <div class="col-4 text-left">
                      <span>房號</span>
                    </div>
                    <div class="col-4 text-left">
                      <span>顧問</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4 text-left" v-if="eventInfo.checkin_time">
                      <b>{{ eventInfo.checkin_date }} {{ eventInfo.checkin_time }}</b>
                    </div>
                    <div class="col-4 text-left">
                      <b>{{ getlistitem('room', eventInfo.room) }}</b>
                    </div>
                    <div class="col-4 text-left">
                      <b>{{ getlistitem('advisor', eventInfo.advisor) }}</b>
                    </div>
                  </div>
                  <div class="row" v-if="eventInfo.checkout_time">
                    <div class="col-4 text-left">
                      <span>Check-out時間</span>
                    </div>
                  </div>
                  <div class="row" v-if="eventInfo.checkout_time">
                    <div class="col-4 text-left">
                      <b>{{ eventInfo.checkout_date }} {{ eventInfo.checkout_time }}</b>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <!-- Back button -->
                  <b-button
                    variant="secondary"
                    @click="back()"
                  >
                    {{ $t('common.button.back') }}
                  </b-button>
                  <span>&nbsp;</span>
                  <!-- End back button -->
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" style="display: none">
            <button
              id="eventModal_close"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
        </div>
      </div>
      </div>
    </div>
    <b-button
      id="eventModal_open"
      variant="outline-success"
      style="display: none"
      data-bs-toggle="modal"
      data-bs-target="#eventModal"
      >{{ $t('common.button.confirm') }}
    </b-button>
    <!-- End of Modal for Event -->

    <!-- Function list -->
    <div class="offcanvas offcanvas-start" tabindex="-1" id="functionlistoffcanvas">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title text-center">Booking System功能表</h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body text-left">
        <div class="row">
          <div class="col-12 text-left">
            <b-button
              class="mx-1 position-relative"
              variant="outline-secondary"
              @click="advisor_mode()"
            >
              <span>會員覆查表</span>
            </b-button>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-12 text-left">
            <b-button
              class="mx-1 position-relative"
              variant="outline-secondary"
              @click="MemberBlockList()"
            >
              <span>注意 / 黑名單會員</span>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- End of Function list -->

    <template v-if="staff == 'all'">
    <div class="padonly">
      <div class="row">
        <div class="col-3 text-left">
          <b-button
            size="sm"
            variant="outline-danger"
            data-bs-toggle="offcanvas"
            data-bs-target="#without_therapistoffcanvas"
            >
              <span :class="without_therapist_list.length > 0 ? 'without_therapist' : ''">{{ without_therapist_list.length }} 間房未有理療師</span>
            </b-button
          >
          <DxCheckBox
            v-model:value="allowAdding"
            text="允許Adding"
            style="display: none"
          />
        </div>
        <div class="col-6 text-center">
          <span v-if="sourceList == 'active'" style="color:green;">正在顯示預約列表</span>
          <span v-if="sourceList == 'cancel'" style="color:red;">正在顯示取消列表</span>
        </div>
        <div class="col-1 text-right">
          <b-button
            class="mx-1 position-relative"
            size="sm"
            variant="outline-warning"
            @click="get_api()"
            >
              <span :class="need_update ? 'need_update' : ''">{{ $t('common.button.update') }}</span>
              <b-badge v-show='need_update' variant="light" text-indicator style="transform: translate(-10%, -30%) !important; left:86% !important; border-radius: 1rem !important; font-size: 0.5rem !important;">
                <span style="color: red; font-size: 1rem !important;">!</span>
              </b-badge>
          </b-button>
        </div>
        <div class="col-2 text-right">
          <b-button
            size="sm"
            variant="outline-secondary"
            @click="changeDataSource()"
            >
              <span v-if="sourceList == 'active'">查看取消列表</span>
              <span v-if="sourceList == 'cancel'">查看預約列表</span>
            </b-button
          >
        </div>
      </div>
      <div class="phoneonly">
        <div class="row">
          <div class="col-3 text-left">
            <b-button
              class="mx-1 position-relative"
              size="sm"
              variant="outline-warning"
              @click="get_api()"
              >
                <span :class="need_update ? 'need_update' : ''">{{ $t('common.button.update') }}</span>
                <b-badge v-show='need_update' variant="light" text-indicator style="transform: translate(-10%, -30%) !important; left:86% !important; border-radius: 1rem !important; font-size: 0.5rem !important;">
                  <span style="color: red; font-size: 1rem !important;">!</span>
                </b-badge>
            </b-button>
          </div>
          <div class="col-4 text-center">
            <span v-if="sourceList == 'active'" style="color:green;">預約列表</span>
            <span v-if="sourceList == 'cancel'" style="color:red;">取消列表</span>
          </div>
          <div class="col-5 text-right">
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="changeDataSource()"
              >
                <span v-if="sourceList == 'active'">查看取消列表</span>
                <span v-if="sourceList == 'cancel'">查看預約列表</span>
              </b-button
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
            <div class="dx-viewport">
              <DxScheduler
                :ref="bookingScheduler"
                time-zone="Asia/Hong_Kong"
                :data-source="dataSourceList"
                :groups="groups"
                :current-date="currentDate"
                :start-day-hour="11"
                :end-day-hour="23"
                :cross-scrolling-enabled="true"
                :show-all-day-panel="false"
                :current-view="currentView"
                :views="views"
                :cell-duration="15"
                :on-appointment-click="create_event"
                @appointmentDblClick="create_event"
                appointment-template="show_event"
                time-cell-template="timeCellTemplate"
                appointment-tooltip-template="show_event_tooltip"
                @appointment-updated="drag_event"
                :adaptivity-enabled="true"
                date-serialization-format="yyyy-MM-ddTHH:mm:ss"
                :on-option-changed="optionChangeHandle"
                :use-drop-down-view-switcher="false"
                :show-current-time-indicator="false"
              >
                <DxEditing
                  :allowAdding="allowAdding"
                  :allowDeleting="true"
                  :allowUpdating="true"
                  :allowResizing="false"
                  :allowDragging="allowDragging"
                />
                <DxResource
                  :allow-multiple="false"
                  :data-source="roster_list"
                  field-expr="therapist"
                  :display-expr="getName"
                  label="Priority"
                />
                <template #show_event="{ data }">
                  <div v-if="data.appointmentData.special_event > 0" class="disable-date">
                    <br>
                    <img v-if="data.appointmentData.special_event == 1" :src="'./bowl_rice.svg'" width="30" height="25"/>
                    <font-awesome-icon v-if="data.appointmentData.special_event == 2" :icon="['fas', 'user-slash']" size="lg"/>
                    <img v-if="data.appointmentData.special_event == 3" :src="'./person_chalkboard.svg'" width="30" height="25"/>            
                    <img v-if="data.appointmentData.special_event == 4" :src="'./people_group.svg'" width="30" height="25"/>
                    <font-awesome-icon v-if="data.appointmentData.special_event == 5" :icon="['fas', 'thumbtack']" size="lg"/>
                    <br>
                    <b style="font-size: 17px !important;">{{ getlistitem('special_event', data.appointmentData.special_event) }}</b><br>
                    <span style="font-size: 17px !important;">{{ data.appointmentData.remark }}</span>
                  </div>
                  <div v-else :class="data.appointmentData.arrival_time ? data.appointmentData.gender == 'M' ? 'genderM genderMBG' : 'genderF' : data.appointmentData.gender == 'M' ? 'genderMBG' : ''">
                    <div v-show="view_mode == 'day'">
                      <div :class="data.appointmentData.new_join == '1' ? data.appointmentData.created_from == '1' ? 'newJoin' : data.appointmentData.created_from == '2' ? 'CallTeam' : 'WTSTeam' : ''">
                        <span v-show="data.appointmentData.reconfirm == 'Phone'"><font-awesome-icon :icon="['fas', 'phone']" size="lg"/>&nbsp;</span>
                        <span v-show="data.appointmentData.reconfirm == 'Whatsapp'"><font-awesome-icon :icon="['fab', 'whatsapp']" size="lg"/>&nbsp;</span>
                        {{ data.appointmentData.member_no }}
                        <span v-show="data.appointmentData.specify == '1'"><font-awesome-icon :icon="['fas', 'star']" size="lg"/></span>
                        <span v-show="data.appointmentData.new_join == '1'">(新會員)</span>
                        <span class="newJoin" v-show="data.appointmentData.recommend == '1'"> (推)</span>
                        <span v-show="data.appointmentData.org_therapist.length > 1" style="color:red; font-size: 14px;"> =</span>
                      </div>
                      <span v-show="data.appointmentData.nickname">{{ data.appointmentData.nickname }}&nbsp;</span>
                      <span v-show="!data.appointmentData.nickname">{{ data.appointmentData.name }}&nbsp;</span>
                      <span v-show="data.appointmentData.member_type">({{ getlistitem('member_type', data.appointmentData.member_type) }})&nbsp;</span>
                      <br>
                      <span v-show="data.appointmentData.remark">備註: {{ data.appointmentData.remark }}<br></span>
                      <div :class="data.appointmentData.without_therapist == '1' ? 'without_therapist' : ''">
                        <span v-show="data.appointmentData.room">房號: <span>{{ getlistitem('room', data.appointmentData.room) }}</span>&nbsp;</span>
                      </div>
                      <span v-show="data.appointmentData.machine.length > 0">儀器機: {{ getlistitem('machine', data.appointmentData.machine) }}<br></span>
                      <div :class="data.appointmentData.checkin_date && !data.appointmentData.checkout_date && (today_date > data.appointmentData.booking_date || currentTime > data.appointmentData.end_time) ? 'without_therapist' : ''">
                        <span v-show="data.appointmentData.checkin_date && !data.appointmentData.checkout_date"><img :src="'./In.svg'" width="40" height="33"/></span>
                        <span v-show="data.appointmentData.checkout_date"><img :src="'./Out.svg'" width="40" height="33"/></span>
                      </div>
                      <span v-show="data.appointmentData.cancel_reason"><br>取消原因: {{ getlistitem('cancel_reason', data.appointmentData.cancel_reason) }}</span>
                      <span v-show="data.appointmentData.cancel_reason_remark">-{{ data.appointmentData.cancel_reason_remark }}</span>
                      <br>
                      <div v-show="data.appointmentData.block_list" style="padding:12px" :style="{ 'background-color': data.appointmentData.block_list_color}">
                        <img :src="'./triangle_exclamation.svg'" width="30" height="25"/>
                        <br>
                        <b style="color:white; white-space:break-spaces;">{{ data.appointmentData.block_list_color_desc }}</b>
                        <br>
                        <b v-show="data.appointmentData.block_list_remark" style="color:white; white-space:break-spaces;">{{ data.appointmentData.block_list_remark }}</b>
                      </div>
                    </div>
                    <div v-show="view_mode == 'agenda'">
                      <div :class="data.appointmentData.new_join == '1' ? 'newJoin' : ''">
                        <span v-show="data.appointmentData.reconfirm == 'Phone'"><font-awesome-icon :icon="['fas', 'phone']" size="lg"/>&nbsp;</span>
                        <span v-show="data.appointmentData.reconfirm == 'Whatsapp'"><font-awesome-icon :icon="['fab', 'whatsapp']" size="lg"/>&nbsp;</span>
                        <span>{{ data.appointmentData.member_no }}&nbsp;</span>
                        <span v-show="data.appointmentData.nickname">{{ data.appointmentData.nickname }}&nbsp;</span>
                        <span v-show="!data.appointmentData.nickname">{{ data.appointmentData.name }}&nbsp;</span>
                        <span v-show="data.appointmentData.new_join == '1'">(新會員)&nbsp;</span>
                        <span class="newJoin" v-show="data.appointmentData.recommend == '1'"> (推)&nbsp;</span>
                        <span v-show="data.appointmentData.specify == '1'"><font-awesome-icon :icon="['fas', 'star']" size="lg"/>&nbsp;</span>
                        <span v-show="data.appointmentData.room">房號: <span>{{ getlistitem('room', data.appointmentData.room) }}</span>&nbsp;</span>
                        <span v-show="data.appointmentData.remark">備註: {{ data.appointmentData.remark }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <template #timeCellTemplate="{ data }">
                  <template v-if="data.text">
                    <div style="font-size: 16px;">
                      <span>{{ get_count_name(data) }}<br></span>
                      <span style="color:#0f6cf7;">男</span><span v-if="data.used_room_M">:{{ data.used_room_M }}&nbsp;&nbsp;</span><span style="color:#f54545;">女</span><span v-if="data.used_room_F">:{{ data.used_room_F }}</span><br>
                      <span v-if="data.used_room">全部:{{ data.used_room }}</span>
                    </div>
                  </template>
                </template>
                <DxScrolling
                  mode="virtual"
                />
              </DxScheduler>
          </div>
        </div>
      </div>
    </div>

    <!--Phone version read only-->
    <div class="phoneonly">
      <div class="row">
        <div class="col-4 text-right">
        </div>
        <div class="col-4 text-center">
          <span v-if="sourceList == 'active'" style="color:green;">預約列表</span>
          <span v-if="sourceList == 'cancel'" style="color:red;">取消列表</span>
        </div>
        <div class="col-4 text-right">
          <b-button
            class="mx-1 position-relative"
            size="sm"
            variant="outline-warning"
            @click="get_api()"
            >
              <span :class="need_update ? 'need_update' : ''">{{ $t('common.button.update') }}</span>
              <b-badge v-show='need_update' variant="light" text-indicator style="transform: translate(-10%, -30%) !important; left:86% !important; border-radius: 1rem !important; font-size: 0.5rem !important;">
                <span style="color: red; font-size: 1rem !important;">!</span>
              </b-badge>
          </b-button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
            <b>{{ staff_name }}</b>
        </div>
      </div>
      <hr>
      <div class="row">
        <template v-if="dataSourcePhoneList.length > 0">
          <template v-for="item in dataSourcePhoneList" :key="item.id">
            <div v-if="item.newheader == 1" class="row">
              <div class="col-12 text-center" style="margin-top:25px ;">
                <b>{{ item.booking_date }}</b>
              </div>
            </div>
            <div :class="item.gender == 'M' ? 'genderMBG_phone' : 'genderFBG_phone'">
              <div class="row">
                <div class="col-6 text-left">
                  <span v-show="item.reconfirm == 'Phone'"><font-awesome-icon :icon="['fas', 'phone']" size="lg"/>&nbsp;</span>
                  <span v-show="item.reconfirm == 'Whatsapp'"><font-awesome-icon :icon="['fab', 'whatsapp']" size="lg"/>&nbsp;</span>
                  <span v-show="item.specify == '1' && (item.therapist == item.org_therapist[0])"><font-awesome-icon :icon="['fas', 'star']" size="lg"/></span>
                  <span v-show="item.new_join == '1'">(新會員)</span>
                  <span class="newJoin" v-show="item.recommend == '1'"> (推)</span>
                </div>
                <div class="col-6 text-right">
                  <b>{{ item.start_time }}-{{ item.end_time }}</b>
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-left">
                  <span>{{ item.member_no.toUpperCase() }}</span>
                  <!--
                  <span v-show="item.nickname">{{ item.nickname }}&nbsp;</span>
                  <span v-show="!item.nickname">{{ item.name }}&nbsp;</span>-->
                </div>
              </div>
              <div v-if="item.room && !role_check(3) && !role_check(34)" class="row">
                <div class="col-12 text-left">
                  <span>房號: {{ getlistitem('room', item.room) }}&nbsp;</span>
                  <span v-show="item.checkin_date && !item.checkout_date"><img :src="'./In.svg'" width="40" height="33"/></span>
                  <span v-show="item.checkout_date"><img :src="'./Out.svg'" width="40" height="33"/></span>
                </div>
              </div>
              <div v-if="role_check(3)" class="row">
                <div class="col-12 text-left">
                  <span>Check In: {{ item.checkin_time }}</span>
                </div>
              </div>
              <div v-if="role_check(3)" class="row">
                <div class="col-12 text-left">
                  <b-form-checkbox
                    v-model="item.cus_todo"
                    value="1"
                    unchecked-value="0"
                    disabled
                  >
                  <span>已處理</span>
                  </b-form-checkbox>
                </div>
              </div>
              <div v-if="role_check(3)" class="row">
                <div class="col-12 text-left">
                  <span>備註: {{ item.cus_todo_remark}}</span>
                </div>
              </div>
              <div v-show="item.remark && !role_check(3)" class="row">
                <div class="col-12 text-left">
                  <span>備註: {{ item.remark }}</span>
                </div>
              </div>
            </div>
          </template>
        </template>
        <div v-else class="row">
          <div class="col-12 text-center">
            <span style="color:red;">沒有預約記錄</span>
          </div>
        </div>
      </div>
    </div>
    </template>
    <template v-if="staff != 'all'">
      <div class="row" style="display:none;">
        <div class="col-12">
            <div class="dx-viewport">
              <DxScheduler
                :ref="bookingScheduler"
                time-zone="Asia/Hong_Kong"
                :data-source="dataSourceList"
                :groups="groups"
                :current-date="currentDate"
                :start-day-hour="11"
                :end-day-hour="23"
                :cross-scrolling-enabled="true"
                :show-all-day-panel="false"
                :current-view="currentView"
                :views="views"
                :cell-duration="15"
                :on-appointment-click="create_event"
                @appointmentDblClick="create_event"
                appointment-template="show_event"
                time-cell-template="timeCellTemplate"
                appointment-tooltip-template="show_event_tooltip"
                @appointment-updated="drag_event"
                :adaptivity-enabled="true"
                date-serialization-format="yyyy-MM-ddTHH:mm:ss"
                :on-option-changed="optionChangeHandle"
                :use-drop-down-view-switcher="false"
                :show-current-time-indicator="false"
              >
                <DxEditing
                  :allowAdding="allowAdding"
                  :allowDeleting="true"
                  :allowUpdating="true"
                  :allowResizing="false"
                  :allowDragging="allowDragging"
                />
                <DxResource
                  :allow-multiple="false"
                  :data-source="roster_list"
                  field-expr="therapist"
                  :display-expr="getName"
                  label="Priority"
                />
                <template #show_event="{ data }">
                  <div v-if="data.appointmentData.special_event > 0" class="disable-date">
                    <br>
                    <img v-if="data.appointmentData.special_event == 1" :src="'./bowl_rice.svg'" width="30" height="25"/>
                    <font-awesome-icon v-if="data.appointmentData.special_event == 2" :icon="['fas', 'user-slash']" size="lg"/>
                    <img v-if="data.appointmentData.special_event == 3" :src="'./person_chalkboard.svg'" width="30" height="25"/>            
                    <img v-if="data.appointmentData.special_event == 4" :src="'./people_group.svg'" width="30" height="25"/>
                    <font-awesome-icon v-if="data.appointmentData.special_event == 5" :icon="['fas', 'thumbtack']" size="lg"/>
                    <br>
                    <b style="font-size: 17px !important;">{{ getlistitem('special_event', data.appointmentData.special_event) }}</b><br>
                    <span style="font-size: 17px !important;">{{ data.appointmentData.remark }}</span>
                  </div>
                  <div v-else :class="data.appointmentData.arrival_time ? data.appointmentData.gender == 'M' ? 'genderM genderMBG' : 'genderF' : data.appointmentData.gender == 'M' ? 'genderMBG' : ''">
                    <div v-show="view_mode == 'day'">
                      <div :class="data.appointmentData.new_join == '1' ? data.appointmentData.created_from == '1' ? 'newJoin' : data.appointmentData.created_from == '2' ? 'CallTeam' : 'WTSTeam' : ''">
                        <span v-show="data.appointmentData.reconfirm == 'Phone'"><font-awesome-icon :icon="['fas', 'phone']" size="lg"/>&nbsp;</span>
                        <span v-show="data.appointmentData.reconfirm == 'Whatsapp'"><font-awesome-icon :icon="['fab', 'whatsapp']" size="lg"/>&nbsp;</span>
                        {{ data.appointmentData.member_no }}
                        <span v-show="data.appointmentData.specify == '1'"><font-awesome-icon :icon="['fas', 'star']" size="lg"/></span>
                        <span v-show="data.appointmentData.new_join == '1'">(新會員)</span>
                        <span class="newJoin" v-show="data.appointmentData.recommend == '1'"> (推)</span>
                        <span v-show="data.appointmentData.org_therapist.length > 1" style="color:red; font-size: 14px;"> =</span>
                      </div>
                      <span v-show="data.appointmentData.nickname">{{ data.appointmentData.nickname }}&nbsp;</span>
                      <span v-show="!data.appointmentData.nickname">{{ data.appointmentData.name }}&nbsp;</span>
                      <span v-show="data.appointmentData.member_type">({{ getlistitem('member_type', data.appointmentData.member_type) }})&nbsp;</span>
                      <br>
                      <span v-show="data.appointmentData.remark">備註: {{ data.appointmentData.remark }}<br></span>
                      <div :class="data.appointmentData.without_therapist == '1' ? 'without_therapist' : ''">
                        <span v-show="data.appointmentData.room">房號: <span>{{ getlistitem('room', data.appointmentData.room) }}</span>&nbsp;</span>
                      </div>
                      <span v-show="data.appointmentData.machine.length > 0">儀器機: {{ getlistitem('machine', data.appointmentData.machine) }}<br></span>
                      <div :class="data.appointmentData.checkin_date && !data.appointmentData.checkout_date && (today_date > data.appointmentData.booking_date || currentTime > data.appointmentData.end_time) ? 'without_therapist' : ''">
                        <span v-show="data.appointmentData.checkin_date && !data.appointmentData.checkout_date"><img :src="'./In.svg'" width="40" height="33"/></span>
                        <span v-show="data.appointmentData.checkout_date"><img :src="'./Out.svg'" width="40" height="33"/></span>
                      </div>
                    </div>
                    <div v-show="view_mode == 'agenda'">
                      <div :class="data.appointmentData.new_join == '1' ? 'newJoin' : ''">
                        <span v-show="data.appointmentData.reconfirm == 'Phone'"><font-awesome-icon :icon="['fas', 'phone']" size="lg"/>&nbsp;</span>
                        <span v-show="data.appointmentData.reconfirm == 'Whatsapp'"><font-awesome-icon :icon="['fab', 'whatsapp']" size="lg"/>&nbsp;</span>
                        <span>{{ data.appointmentData.member_no }}&nbsp;</span>
                        <span v-show="data.appointmentData.nickname">{{ data.appointmentData.nickname }}&nbsp;</span>
                        <span v-show="!data.appointmentData.nickname">{{ data.appointmentData.name }}&nbsp;</span>
                        <span v-show="data.appointmentData.new_join == '1'">(新會員)&nbsp;</span>
                        <span class="newJoin" v-show="data.appointmentData.recommend == '1'"> (推)&nbsp;</span>
                        <span v-show="data.appointmentData.specify == '1'"><font-awesome-icon :icon="['fas', 'star']" size="lg"/>&nbsp;</span>
                        <span v-show="data.appointmentData.room">房號: <span>{{ getlistitem('room', data.appointmentData.room) }}</span>&nbsp;</span>
                        <span v-show="data.appointmentData.remark">備註: {{ data.appointmentData.remark }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <template #timeCellTemplate="{ data }">
                  <template v-if="data.text">
                    <div style="font-size: 16px;">
                      <span>{{ get_count_name(data) }}<br></span>
                      <span style="color:#0f6cf7;">男</span><span v-if="data.used_room_M">:{{ data.used_room_M }}&nbsp;&nbsp;</span><span style="color:#f54545;">女</span><span v-if="data.used_room_F">:{{ data.used_room_F }}</span><br>
                      <span v-if="data.used_room">全部:{{ data.used_room }}</span>
                    </div>
                  </template>
                </template>
                <DxScrolling
                  mode="virtual"
                />
              </DxScheduler>
          </div>
        </div>
      </div>
      <!--Phone version read only-->
      <div class="row">
        <div class="col-4 text-right">
        </div>
        <div class="col-4 text-center">
          <span v-if="sourceList == 'active'" style="color:green;">預約列表</span>
          <span v-if="sourceList == 'cancel'" style="color:red;">取消列表</span>
        </div>
        <div class="col-4 text-right">
          <b-button
            class="mx-1 position-relative"
            size="sm"
            variant="outline-warning"
            @click="get_api()"
            >
              <span :class="need_update ? 'need_update' : ''">{{ $t('common.button.update') }}</span>
              <b-badge v-show='need_update' variant="light" text-indicator style="transform: translate(-10%, -30%) !important; left:86% !important; border-radius: 1rem !important; font-size: 0.5rem !important;">
                <span style="color: red; font-size: 1rem !important;">!</span>
              </b-badge>
          </b-button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
            <b>{{ staff_name }}</b>
        </div>
      </div>
      <hr>
      <div class="row">
        <template v-if="dataSourcePhoneList.length > 0">
          <template v-for="item in dataSourcePhoneList" :key="item.id">
            <div v-if="item.newheader == 1" class="row">
              <div class="col-12 text-center" style="margin-top:25px ;">
                <b>{{ item.booking_date }}</b>
              </div>
            </div>
            <div :class="item.special_event > 0 ? 'defaultBG_phone' : item.gender == 'M' ? 'genderMBG_phone' : 'genderFBG_phone'">
              <div class="row">
                <div class="col-6 text-left">
                  <span v-show="item.reconfirm == 'Phone'"><font-awesome-icon :icon="['fas', 'phone']" size="lg"/>&nbsp;</span>
                  <span v-show="item.reconfirm == 'Whatsapp'"><font-awesome-icon :icon="['fab', 'whatsapp']" size="lg"/>&nbsp;</span>
                  <span v-show="item.specify == '1' && (item.therapist == item.org_therapist[0])"><font-awesome-icon :icon="['fas', 'star']" size="lg"/></span>
                  <span v-show="item.new_join == '1'">(新會員)</span>
                  <span class="newJoin" v-show="item.recommend == '1'"> (推)</span>
                  <span v-if="item.special_event > 0"><img :src="'./bowl_rice.svg'" width="30" height="25"/>{{ getlistitem('special_event', item.special_event) }}</span>
                </div>
                <div class="col-6 text-right">
                  <b>{{ item.start_time }}-{{ item.end_time }}</b>
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-left">
                  <span v-if="item.member_no">{{ item.member_no.toUpperCase() }}</span>
                  <!--
                  <span v-show="item.nickname">{{ item.nickname }}&nbsp;</span>
                  <span v-show="!item.nickname">{{ item.name }}&nbsp;</span>-->
                </div>
              </div>
              <div v-if="item.room && !role_check(34)" class="row">
                <div class="col-12 text-left">
                  <span>房號: {{ getlistitem('room', item.room) }}&nbsp;</span>
                  <span v-show="item.checkin_date && !item.checkout_date"><img :src="'./In.svg'" width="40" height="33"/></span>
                  <span v-show="item.checkout_date"><img :src="'./Out.svg'" width="40" height="33"/></span>
                </div>
              </div>
              <!--
              <div v-show="item.machine" class="row">
                <div class="col-12 text-left">
                  <span>儀器機: {{ getlistitem('machine', item.machine) }}</span>
                </div>
              </div>
              -->
              <div v-show="item.remark && !role_check(34)" class="row">
                <div class="col-12 text-left">
                  <span>備註: {{ item.remark }}</span>
                </div>
              </div>
              <div v-show="item.sports_science_type && role_check(34)" class="row">
                <div class="col-12 text-left">
                  <span v-if="item.sports_science_type == 1">會員類別: 首次會員</span>
                  <span v-if="item.sports_science_type == 2">會員類別: 療程會員</span>
                </div>
              </div>
            </div>
          </template>
        </template>
        <div v-else class="row">
          <div class="col-12 text-center">
            <span style="color:red;">沒有預約記錄</span>
          </div>
        </div>
      </div>
    </template>

  </b-overlay>
</template>

<script>
import { DxScheduler, DxResource, DxScrolling, DxEditing} from 'devextreme-vue/scheduler'

const bookingScheduler = 'data-grid';
export default {
  components: {
    DxScheduler,
    DxResource,
    DxScrolling,
    DxEditing,
  },
  name: 'BookingList',
  data() {
    return {
      allowed: false,
      modalShow: false,
      followup_mode: false,
      need_update: false,
      version: '',
      version_update: false,
      auto_update_msg: false,
      branch_id: '',
      staff: '',
      staff_code: '',
      staff_id: '',
      staff_name: '',
      staff_code_checked: false,
      check_staff_code: true,
      vaild_staff_code: true,
      role: [],
      permission: [],
      bookingScheduler,
      views: ['day'],
      view_mode: 'day',
      //currentView: 'Horizontal Grouping',
      currentView: 'day',
      groups: ['therapist'],
      draggingGroupName: 'eventGroup',
      currentDate: new Date(),
      currentDateFormat: '',
      endDateFormat: '',
      phoneendDateFormat: '',
      allowAdding: false,
      allowDragging: false,
      show: false,
      sourceList: 'active',
      cancel_reason_list: [],
      member_type_list: [],
      lang: '',
      eventAction: '',
      eventInfo: [],
      old_eventInfo: [],
      branch_list: [],
      room_list: [],
      machine_list: [],
      gender_list: [
        { value: 'F', name: "F" },
        { value: 'M', name: 'M' },
      ],
      reconfirm_list: [
        { value: 'None', name: '未確認' },
        { value: 'Phone', name: 'Phone' },
        { value: 'Whatsapp', name: 'Whatsapp' },
      ],
      roster_list: [],
      therapist_list: [
        {
          name: '舊客Waiting List',
          nickname: '舊客Waiting List',
          text: '舊客Waiting List',
          id: 9999,
          color: '#ededed',
        },
        {
          name: '新客Waiting List',
          nickname: '新客Waiting List',
          text: '新客Waiting List',
          id: 99999,
          color: '#ededed',
        }
      ],
      advisor_list: [],
      chinese_medicine_list: [],
      nutritionist_list: [],
      sports_science_consultant_list: [],
            sports_science_type_list: [
        {
          id: 1,
          name: '首次會員',
        },
        {
          id: 2,
          name: '療程會員',
        },
      ],
      tasks: [
        {
          text: '特別項目',
        }
      ],
      special_event_list: [],
      dataSource: [],
      phonedataSource: [],
      currentTime: '',
      today_date: '',
      count_room_list: [],
    }
  },
  computed: {
    dataSourceList: function () {
      var self = this
      if(self.sourceList == "active"){
        return self.dataSource.filter(function (item) {
          return item.status == 1
        })
      }else{
        return self.dataSource.filter(function (item) {
          return item.status == -1
        })
      }
    },
    dataSourcePhoneList: function () {
      var self = this
      console.log(self.phonedataSource)
      if(self.role_check(3)){
        var list = self.phonedataSource.filter(function (item) {
          return item.status == 1 && item.advisor.includes(self.staff_id.toString()) && item.special_event == 0 && item.booking_date == self.today_date
        })
        return list.filter((obj, index) => {
          return index === list.findIndex(o => obj.id === o.id)
        })
      }else if(self.role_check(34)){
        return self.phonedataSource.filter(function (item) {
          return item.status == 1 && item.sports_science_consultant == self.staff_id
        })
      }else{
        return self.phonedataSource.filter(function (item) {
          return item.status == 1 && item.therapist == self.staff_id
        })
      }
    },
    dataGrid: function() {
      return this.$refs[bookingScheduler].instance;
    },
    without_therapist_list: function () {
      var self = this
      var list = self.dataSource.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.id === value.id
        ))
      )
      return list.filter(function (item) {
        return item.without_therapist == 1
      })
    },
    room_list_F: function () {
      var self = this
      return self.room_list.filter(function (item) {
        return item.type == 'F'
      })
    },
    room_list_M: function () {
      var self = this
      return self.room_list.filter(function (item) {
        return item.type == 'M'
      })
    },
  },
  watch: {
  },
  created() {
    var self = this
    var fd_offset = self.currentDate.getTimezoneOffset()
    var date = new Date(self.currentDate.getTime() - fd_offset * 60 * 1000)
    self.currentDateFormat = date.toISOString().split('T')[0]
    self.today_date = date.toISOString().split('T')[0]
    self.endDateFormat = self.currentDateFormat
    self.branch = this.$route.params.branch
    self.lang = localStorage.getItem('locale')
    var ctoday = new Date(self.currentDateFormat)
    var ctomorrow = new Date(ctoday)
    ctomorrow.setDate(ctoday.getDate()+1)
    ctomorrow.toLocaleDateString()
    var cendfd_offset = ctomorrow.getTimezoneOffset()
    var cenddate = new Date(ctomorrow.getTime() - cendfd_offset * 60 * 1000)
    self.phoneendDateFormat = cenddate.toISOString().split('T')[0]
    setTimeout(function () {
      document.getElementById('confirmModal_open').click()
      setTimeout(function () {
        document.getElementById('staff_code').focus()
      }, 700)
    }, 500)
    setInterval(this.getNow, 1000)
    //self.getrealip()
  },
  mounted() {
    var self = this
    this.emitter.on('lang_changed', (lang) => {
      self.initialize()
      self.lang = lang
    })
    window.Echo.channel('booking_channel')
    .listen('.booking', (e) => {
      if(self.branch_id == e.message){
        self.need_update = true
        if(self.followup_mode){
          self.auto_update_msg = true
          self.get_api()
          setTimeout(function () {
            self.auto_update_msg = false
          }, 2000)
        }
      }
    })
  },
  methods: {
    getrealip(){
      const self = this
      var branch_list = []
      this.axios
        .post('/booking/prepare', this.formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(function (response) {
          branch_list = response.data.branch_list
          self.axios
          .get('https://record.ankh.com.hk/api/getip', {})
          .then(function (res) {
            const ip = res.data.ip
            branch_list.forEach((item) => {
              if(item.ip_address == ip || item.ip_address2 == ip){
                self.allowed = true
              }
            })
          })
          .catch((error) => { 
            console.error(error)
            self.allowed = true
          })
        })
    },
    role_check(role) {
      var self = this
      if (self.role.includes(role)) {
        return true
      } else {
        return false
      }
    },
    change_mode() {
      //this.followup_mode = true
      //this.get_api()
      let route = this.$router.resolve({path: '/booking/followup/'+this.branch, target: '_blank'})
      window.open(route.href, '_blank', 'menubar=yes,status=yes,resizable=yes,width=1600,height=800')
    },
    advisor_mode() {
      let route = this.$router.resolve({path: '/booking/advisor/'+this.branch, target: '_blank'})
      window.open(route.href, '_blank', 'menubar=yes,status=yes,resizable=yes,width=1600,height=800')
    },
    MemberBlockList() {
      let route = this.$router.resolve({path: '/booking/MemberBlockList', target: '_blank'})
      window.open(route.href, '_blank', 'menubar=yes,status=yes,resizable=yes,width=1600,height=800')
    },
    getNow: function() {
      var self = this
      const today = new Date();
      const time = today.getHours().toString().padStart(2, '0') + ":" + today.getMinutes().toString().padStart(2, '0')
      self.currentTime = time
    },
    refreshDataGrid() {
      var self = this
      self.dataGrid.getDataSource().reload()
      setTimeout(function () {
        self.allowAdding = true
      }, 100)
      setTimeout(function () {
        self.allowAdding = false
      }, 200)
      setTimeout(function () {
        var schedulerScrollable = self.dataGrid._workSpace.getScrollable()
        schedulerScrollable.scrollTo(self.position)
        self.show = false
      }, 300)
    },
    create_event(e) {
      e.cancel = true
      var self = this
      self.dragmode = false
      self.eventAction = "edit"
      self.eventInfo = e.appointmentData
      self.old_eventInfo = {
        id: self.eventInfo.id,
        member_no: self.eventInfo.member_no,
        name: self.eventInfo.name,
        nickname: self.eventInfo.nickname,
        gender: self.eventInfo.gender,
        therapist: self.eventInfo.therapist,
        org_therapist: self.eventInfo.org_therapist,
        without_therapist: self.eventInfo.without_therapist,
        advisor: self.eventInfo.advisor,
        nutritionist: self.eventInfo.nutritionist,
        sports_science_consultant: self.eventInfo.sports_science_consultant,
        org_sports_science_consultant: self.eventInfo.org_sports_science_consultant,
        sports_science_type: self.eventInfo.sports_science_type,
        chinese_medicine: self.eventInfo.chinese_medicine,
        bookingDate: self.eventInfo.bookingDate,
        bookingStartTime: self.eventInfo.bookingStartTime,
        bookingEndTime: self.eventInfo.bookingEndTime,
        arrivalTime: self.eventInfo.arrivalTime,
        arrival_time: self.eventInfo.arrival_time,
        startDate: self.eventInfo.startDate,
        endDate: self.eventInfo.endDate,
        start_time: self.eventInfo.start_time,
        end_time: self.eventInfo.end_time,
        room: self.eventInfo.room,
        machine: self.eventInfo.machine,
        new_join: self.eventInfo.new_join,
        specify: self.eventInfo.specify,
        recommend: self.eventInfo.recommend,
        reconfirm: self.eventInfo.reconfirm,
        remark: self.eventInfo.remark,
        remark_call: self.eventInfo.remark_call,
        remark_internal: self.eventInfo.remark_internal,
        member_type: self.eventInfo.member_type,
        booking_show: self.eventInfo.booking_show,
        deal: self.eventInfo.deal,
        special_event: self.eventInfo.special_event,
        checkin_date: self.eventInfo.checkin_date,
        checkin_time: self.eventInfo.checkin_time,
        checkout_date: self.eventInfo.checkout_date,
        checkout_time: self.eventInfo.checkout_time,
        status: self.eventInfo.status,
        block_list: self.eventInfo.block_list,
        block_list_remark: self.eventInfo.block_list_remark,
        block_list_color: self.eventInfo.block_list_color,
        block_list_color_desc: self.eventInfo.block_list_color_desc,
      }
      setTimeout(function () {
        document.getElementById('eventModal_open').click()
      }, 300)
      if(self.eventInfo.member_no){
        self.member_check(self.eventInfo)
      }
    },
    booking_click(item) {
      var self = this
      self.dragmode = false
      self.eventAction = "edit"
      self.eventInfo = item
      self.old_eventInfo = {
        id: self.eventInfo.id,
        member_no: self.eventInfo.member_no,
        name: self.eventInfo.name,
        nickname: self.eventInfo.nickname,
        gender: self.eventInfo.gender,
        therapist: self.eventInfo.therapist,
        org_therapist: self.eventInfo.org_therapist,
        without_therapist: self.eventInfo.without_therapist,
        advisor: self.eventInfo.advisor,
        nutritionist: self.eventInfo.nutritionist,
        sports_science_consultant: self.eventInfo.sports_science_consultant,
        org_sports_science_consultant: self.eventInfo.org_sports_science_consultant,
        sports_science_type: self.eventInfo.sports_science_type,
        chinese_medicine: self.eventInfo.chinese_medicine,
        bookingDate: self.eventInfo.bookingDate,
        bookingStartTime: self.eventInfo.bookingStartTime,
        bookingEndTime: self.eventInfo.bookingEndTime,
        arrivalTime: self.eventInfo.arrivalTime,
        arrival_time: self.eventInfo.arrival_time,
        startDate: self.eventInfo.startDate,
        endDate: self.eventInfo.endDate,
        start_time: self.eventInfo.start_time,
        end_time: self.eventInfo.end_time,
        room: self.eventInfo.room,
        machine: self.eventInfo.machine,
        new_join: self.eventInfo.new_join,
        specify: self.eventInfo.specify,
        recommend: self.eventInfo.recommend,
        reconfirm: self.eventInfo.reconfirm,
        remark: self.eventInfo.remark,
        remark_call: self.eventInfo.remark_call,
        remark_internal: self.eventInfo.remark_internal,
        member_type: self.eventInfo.member_type,
        booking_show: self.eventInfo.booking_show,
        deal: self.eventInfo.deal,
        special_event: self.eventInfo.special_event,
        checkin_date: self.eventInfo.checkin_date,
        checkin_time: self.eventInfo.checkin_time,
        checkout_date: self.eventInfo.checkout_date,
        checkout_time: self.eventInfo.checkout_time,
        status: self.eventInfo.status,
        block_list: self.eventInfo.block_list,
        block_list_remark: self.eventInfo.block_list_remark,
        block_list_color: self.eventInfo.block_list_color,
        block_list_color_desc: self.eventInfo.block_list_color_desc,
      }
      setTimeout(function () {
        document.getElementById('eventModal_open').click()
      }, 300)
      if(self.eventInfo.member_no){
        self.member_check(self.eventInfo)
      }
    },
    drag_event(e) {
      e.appointmentData.bookingDate = e.appointmentData.startDate.substring(0, 10)
      e.appointmentData.bookingStartTime.hours = e.appointmentData.startDate.substring(11, 13)
      e.appointmentData.bookingStartTime.minutes = e.appointmentData.startDate.substring(14, 16)
      e.appointmentData.bookingEndTime.hours = e.appointmentData.endDate.substring(11, 13)
      e.appointmentData.bookingEndTime.minutes = e.appointmentData.endDate.substring(14, 16)
    }, 
    back() {
      var self = this
      var index = self.dataSource.findIndex(el => el.id === self.eventInfo.id && el.therapist === self.eventInfo.therapist)
      if(index >= 0){
        self.dataSource[self.dataSource.findIndex(el => el.id === self.eventInfo.id && el.therapist === self.eventInfo.therapist)] = self.old_eventInfo
      }
      if(self.dataSource.length > 0){
        if(self.dataSource[self.dataSource.length-1].id == 0){
          self.dataSource.pop()
        }
      }
      document.getElementById('eventModal_close').click()
      //self.refreshDataGrid()
    },
    initialize() {
      var self = this
      self.special_event_list = [
        {
          id: 1,
          event: '午膳'
        },
        {
          id: 2,
          event: '休假'
        },
        {
          id: 3,
          event: '培訓'
        },
        {
          id: 4,
          event: '會議'
        },
        {
          id: 5,
          event: '其他'
        }
      ]
      this.formData = new FormData()
      this.formData.append('branch', self.branch)
      this.axios
      .post('/booking/prepare', this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        self.room_list = response.data.room_list
        self.machine_list = response.data.machine_list
        self.branch_list = response.data.branch_list
        self.cancel_reason_list = response.data.cancel_reason_list
        self.member_type_list = response.data.member_type_list
        var search_branch = response.data.branch_list.filter(function (item) {
          return item.branch == self.branch
        })
        if(search_branch){
          self.branch_id = search_branch[0].id
        }
        var staff_list = response.data.staff_list
        var user_role_list = response.data.user_role_list
        staff_list.forEach((item) => {
          item.text = item.nickname
          if(item.active == 1){
            if(item.shop.includes(self.branch_id) && user_role_list.length > 0){
              var advisor = user_role_list.filter(function (role) {
                return item.id == role.parent_id && role.role == 3 && role.status == 1
              })
              if(advisor.length > 0){
                self.advisor_list.push(item)
              }
              var chinese_medicine = user_role_list.filter(function (role) {
                return item.id == role.parent_id && role.role == 7 && role.status == 1
              })
              if(chinese_medicine.length > 0){
                self.chinese_medicine_list.push(item)
              }
              var nutritionist = user_role_list.filter(function (role) {
                return item.id == role.parent_id && role.role == 12 && role.status == 1
              })
              if(nutritionist.length > 0){
                self.nutritionist_list.push(item)
              }
              var sports_science_consultant = user_role_list.filter(function (role) {
                return item.id == role.parent_id && role.role == 34 && role.status == 1
              })
              if(sports_science_consultant.length > 0){
                self.sports_science_consultant_list.push(item)
              }
            }
          }
        })
        setTimeout(function () {
          self.get_api()
        }, 1000)
      })
    },
    get_api() {
      var self = this
      self.show = true
      var schedulerScrollable = self.dataGrid._workSpace.getScrollable()
      self.position = schedulerScrollable.scrollOffset()
      self.roster_list = [
        {
          name: '舊客Waiting List',
          nickname: '舊客Waiting List',
          text: '舊客Waiting List',
          id: 9999,
          color: '#ededed',
        },
        {
          name: '新客Waiting List',
          nickname: '新客Waiting List',
          text: '新客Waiting List',
          id: 99999,
          color: '#ededed',
        }
      ]
      if(self.staff != 'all'){
        self.roster_list = []
      }
      this.formData = new FormData()
      this.formData.append('staff_id', self.staff)
      this.formData.append('start_booking_date', self.currentDateFormat)
      this.formData.append('end_booking_date', self.endDateFormat)
      this.formData.append('branch', self.branch)
      this.axios
      .post('/booking/master', this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        self.need_update = false
        if(self.version){
          if(self.version != response.data.version){
            self.version_update = true
            setTimeout(function () {
              self.reload()
            }, 5000)
          }
        }else{
          self.version_update = false
          self.version = response.data.version
        }
        self.dataSource = response.data.data
        if(response.data.roster_list.length > 0 ){
          response.data.roster_list.forEach((item) => {
            if(self.staff == 'all' || item.id == self.staff_id){
              item.text = item.nickname
              item.name = item.nickname
              item.color = '#ededed'
              self.roster_list.push(item)
            }
          })
          self.therapist_list = self.roster_list
        }
        if(self.dataSource.length > 0 ){
          self.dataSource.forEach((item, idx, array) => {
            if(item.member_no){
              //self.member_check(item)
            }
            item.bookingDate = item.booking_date
            item.startDate = item.bookingDate+'T'+item.start_time+':00'
            item.endDate = item.bookingDate+'T'+item.end_time+':00'
            item.bookingStartTime = {
              hours: item.start_time.substring(0, 2),
              minutes: item.start_time.substring(3, 5),
              seconds: '00'
            }
            item.bookingEndTime = {
              hours: item.end_time.substring(0, 2),
              minutes: item.end_time.substring(3, 5),
              seconds: '00'
            }
            if(item.arrival_time){
              item.arrivalTime = {
                hours: item.arrival_time.substring(0, 2),
                minutes: item.arrival_time.substring(3, 5),
                seconds: '00'
              }
            }else{
              item.arrivalTime = {
                hours: '00',
                minutes: '00',
                seconds: '0'
              }
            }

            // Advisor array
            var advisor = []
            if(item.advisor){
              var advisor_re = item.advisor
              advisor_re = advisor_re.replace('[', '')
              advisor_re = advisor_re.replace(']', '')
              advisor_re = advisor_re.replace('"', '')
              advisor = advisor_re.split(',')
            }
            item.advisor = advisor

            // Chinese Medicine array
            var chinese_medicine = []
            if(item.chinese_medicine){
              var chinese_medicine_re = item.chinese_medicine
              chinese_medicine_re = chinese_medicine_re.replace('[', '')
              chinese_medicine_re = chinese_medicine_re.replace(']', '')
              chinese_medicine_re = chinese_medicine_re.replace('"', '')
              chinese_medicine = chinese_medicine_re.split(',')
            }
            item.chinese_medicine = chinese_medicine

            // Nutritionist array
            var nutritionist = []
            if(item.nutritionist){
              var nutritionist_re = item.nutritionist
              nutritionist_re = nutritionist_re.replace('[', '')
              nutritionist_re = nutritionist_re.replace(']', '')
              nutritionist_re = nutritionist_re.replace('"', '')
              nutritionist = nutritionist_re.split(',')
            }
            item.nutritionist = nutritionist

            // Sports Science Consultant array
            /*
            var sports_science_consultant = []
            if(item.sports_science_consultant){
              var sports_science_consultant_re = item.sports_science_consultant
              sports_science_consultant_re = sports_science_consultant_re.replace('[', '')
              sports_science_consultant_re = sports_science_consultant_re.replace(']', '')
              sports_science_consultant_re = sports_science_consultant_re.replace('"', '')
              sports_science_consultant = sports_science_consultant_re.split(',')
            }
            item.sports_science_consultant = sports_science_consultant
            */

            // Machine array
            var machine = []
            if(item.machine){
              var machine_re = item.machine
              machine_re = machine_re.replace('[', '')
              machine_re = machine_re.replace(']', '')
              machine_re = machine_re.replace('"', '')
              machine = machine_re.split(',')
            }
            item.machine = machine

            if (idx === array.length - 1){ 
              setTimeout(function () {
                self.count_room_bytime()
              }, 300)
            }
          })
        }else{
          setTimeout(function () {
            self.refreshDataGrid()
          }, 300)
        }
      })
      this.formData = new FormData()
      this.formData.append('staff_id', self.staff)
      this.formData.append('start_booking_date', self.currentDateFormat)
      this.formData.append('end_booking_date', self.phoneendDateFormat)
      this.formData.append('special_event', '-2')
      this.formData.append('branch', self.branch)
      this.formData.append('orderBy', 'PDF')
      this.axios
      .post('/booking/master', this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        self.phonedataSource = response.data.data
        if(self.phonedataSource.length > 0 ){
          self.phonedataSource.forEach((item) => {
            item.bookingDate = item.booking_date
            item.cus_todo = '0'
            item.cus_todo_remark = ''
            if(item.todo_list && item.todo_list.length > 0){
              item.todo_list.forEach((todo) => {
                if(todo.user_id == self.staff_id){
                  item.cus_todo = todo.todo
                  item.cus_todo_remark = todo.todo_remark
                }
              })
            }
          })
        }
      })
    },
    member_check(info) {
      var self = this
      self.check_member_msg = ''
      if (info.member_no) {
        //POS API
        this.axios({
        method: 'post',
        url: 'https://record.ankh.com.hk/api/pos/v1/fetch/member',
        data: {
          find: info.member_no
        },
        headers: {
          'Authorization': `Bearer 68|003iXlulhk5hFHGUHVMyEenUbGxCXPAVDVNJIw38`,
          'X-Requested-With': `XMLHttpRequest`
        },
        withCredentials: true,
        }).then(function (response) {
          if(response.data.result == "success"){
            info.name = response.data.member.cardName
            if(!info.gender){
              info.gender = response.data.member.gender
            }
          }else{
            self.check_member_msg = '---找不到相關會員編號---'
          }
        })
      }
    },
    getlistitem(list, id) {
      var self = this
      var nickname = ""
      var selected = []
      if(list == "special_event"){
        selected = self.special_event_list.find(i => i.id == id)
        if(selected){
          return selected.event
        }
      }else if(list == "therapist"){
        if(id){
          id.forEach((therapist, index) => {
            selected = self.therapist_list.find(i => i.id == therapist)
            if(selected){
              if(index == 0){
                nickname = selected.nickname
              }else{
                nickname += ', '+selected.nickname
              }
            }
          })
          return nickname
        }
      }else if(list == "advisor"){
        if(id){
          id.forEach((advisor, index) => {
            selected = self.advisor_list.find(i => i.id == advisor)
            if(selected){
              if(index == 0){
                nickname = selected.nickname
              }else{
                nickname += ', '+selected.nickname
              }
            }
          })
          return nickname
        }             
      }else if(list == "nutritionist"){
        if(id){
          if(Array.isArray(id) == false){
            id = id.split(",")
          }
          id.forEach((nutritionist, index) => {
            selected = self.nutritionist_list.find(i => i.id == nutritionist)
            if(selected){
              if(index == 0){
                nickname = selected.nickname
              }else{
                nickname += ', '+selected.nickname
              }
            }
          })
          return nickname
        }
      }else if(list == "sports_science_consultant"){
        if(id){
          if(Array.isArray(id) == false){
            id = id.split(",")
          }
          id.forEach((sports_science_consultant, index) => {
            selected = self.sports_science_consultant_list.find(i => i.id == sports_science_consultant)
            if(selected){
              if(index == 0){
                nickname = selected.nickname
              }else{
                nickname += ', '+selected.nickname
              }
            }
          })
          return nickname
        }
      }
      else if(list == "chinese_medicine"){
        if(id){
          if(Array.isArray(id) == false){
            id = id.split(",")
          }
          id.forEach((chinese_medicine, index) => {
            selected = self.chinese_medicine_list.find(i => i.id == chinese_medicine)
            if(selected){
              if(index == 0){
                nickname = selected.nickname
              }else{
                nickname += ', '+selected.nickname
              }
            }
          })
          return nickname
        }
      }else if(list == "room"){
        selected = self.room_list.find(i => i.id == id)
        if(selected){
          return selected.room
        }
      }else if(list == "machine"){
        if(id){
          if(Array.isArray(id) == false){
            id = id.split(",")
          }
          id.forEach((machine, index) => {
            selected = self.machine_list.find(i => i.id == machine)
            if(selected){
              if(index == 0){
                nickname = selected.machine
              }else{
                nickname += ', '+selected.machine
              }
            }
          })
          return nickname
        }
      }else if(list == "cancel_reason"){
        selected = self.cancel_reason_list.find(i => i.id == id)
        if(selected){
          return selected.name_tc
        }
      }else if(list == "member_type"){
        selected = self.member_type_list.find(i => i.id == id)
        if(selected){
          return selected.name_tc
        }
      }
    },
    changeDataSource() {
      var self = this
      if(self.sourceList == "active"){
        self.sourceList = "cancel"
        //self.currentView = "agenda"
      }else{
        self.sourceList = "active"
        //self.currentView = "day"
      }
    },
    optionChangeHandle(args) {
      var self = this
      if (args.name === 'currentDate') {
        var fd_offset = args.value.getTimezoneOffset()
        var date = new Date(args.value.getTime() - fd_offset * 60 * 1000)
        self.currentDateFormat = date.toISOString().split('T')[0]
        if(self.view_mode == 'day'){
          self.endDateFormat = self.currentDateFormat
        }else{
          var ctoday = new Date(self.currentDateFormat)
          var ctomorrow = new Date(ctoday)
          ctomorrow.setDate(ctoday.getDate()+6)
          ctomorrow.toLocaleDateString()
          var cendfd_offset = ctomorrow.getTimezoneOffset()
          var cenddate = new Date(ctomorrow.getTime() - cendfd_offset * 60 * 1000)
          self.endDateFormat = cenddate.toISOString().split('T')[0]
        }
        self.get_api()
        if(self.eventAction == "create"){
          self.eventInfo.bookingDate = self.currentDateFormat
        }
      }
      if (args.name === 'currentView') {
        self.view_mode = args.value
        if(args.value == 'day'){
          self.endDateFormat = self.currentDateFormat
        }else{
          var today = new Date(self.currentDateFormat)
          var tomorrow = new Date(today)
          tomorrow.setDate(today.getDate()+6)
          tomorrow.toLocaleDateString()
          var endfd_offset = tomorrow.getTimezoneOffset()
          var enddate = new Date(tomorrow.getTime() - endfd_offset * 60 * 1000)
          self.endDateFormat = enddate.toISOString().split('T')[0]
        }
        self.get_api()
      }
    },
    staff_code_check() {
      var self = this
      if (self.check_staff_code && self.staff_code) {
        this.axios
          .get('staff/vaild/' + self.staff_code, {})
          .then(function (response) {
            var staff_info = response.data.data
            if(response.data.check_ip == false){
              self.allowed = true
            }
            if(self.staff_code_checked == false){
              self.staff_id = ''
              self.role = []
              if (staff_info.length > 0) {
                self.staff_id = staff_info[0]['id']
                self.staff_name = staff_info[0]['nickname']
                self.role = response.data.role
                var view_role = self.role.findIndex(el => el == '15')
                var ip_role = self.role.findIndex(el => el == '20')
                if(view_role >= 0){
                  if(self.allowed == true || ip_role >= 0){
                    self.vaild_staff_code = true
                    self.role = response.data.role
                    self.permission = response.data.permission
                    var index = self.role.findIndex(el => el == '14')
                    if(index >= 0){
                      self.staff = 'all'
                    }else{
                      self.staff = self.staff_id
                    }
                    document.getElementById('confirmModal_close').click()
                    self.staff_code_checked = true
                    self.show = true
                    self.initialize()
                  }else{
                    self.vaild_staff_code = false
                  }
                }else{
                  self.vaild_staff_code = false
                }
              } else {
                self.vaild_staff_code = false
              }
            }
          })
      }
      document.getElementById('staff_code').blur()
    },
    getName(staff){
      var self = this
      var search_branch = self.branch_list.filter(function (item) {
        return item.id == staff.shop
      })
      if(search_branch.length > 0){
        return staff.name + ' (' + search_branch[0].branch + ')'
      }else{
        return staff.name
      }
    },
    home_page(){
      document.getElementById('confirmModal_close').click()
    },
    count_room_bytime(){
      var self = this
      self.count_room_list = []
      var begin = new Date(self.currentDateFormat.replace(/-/g, "/")+' 11:00')
      var end = new Date(self.currentDateFormat.replace(/-/g, "/")+' 22:30')
      for (var day = begin; day <= end; day.setMinutes(day.getMinutes() + 30)) {
        const offset = day.getTimezoneOffset()
        var offsettime = new Date(day.getTime() - (offset*60*1000))
        var hour =  offsettime.toISOString().split('T')[1].substring(0, 2)
        var minutes =  offsettime.toISOString().split('T')[1].substring(3, 5)
        var hourafter30 = offsettime.toISOString().split('T')[1].substring(0, 2)
        var minutesafter30 =  offsettime.toISOString().split('T')[1].substring(3, 5)
        if(minutes == '30'){
          hourafter30 = parseInt(hour) + 1
          minutesafter30 = '00'
        }else{
          minutesafter30 = '30'
        }
        var starttime = hour+':'+minutes
        var endtime = hourafter30+':'+minutesafter30
        var list = self.dataSource.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.id === value.id
          ))
        )
        var used_room_M = list.filter(function (item) {
          return item.special_event == 0 && item.status > 0 && ((starttime >= item.start_time && item.end_time > starttime) || (starttime < item.start_time && endtime > item.start_time)) && item.gender == 'M' && item.therapist != '9999' && item.therapist != '99999'
        })
        var used_room_F = list.filter(function (item) {
          return item.special_event == 0 && item.status > 0 && ((starttime >= item.start_time && item.end_time > starttime) || (starttime < item.start_time && endtime > item.start_time)) && item.gender == 'F' && item.therapist != '9999' && item.therapist != '99999'
        })
        var used_room = list.filter(function (item) {
          return item.special_event == 0 && item.status > 0 && ((starttime >= item.start_time && item.end_time > starttime) || (starttime < item.start_time && endtime > item.start_time))
        })
        var count_roomInfo = {
          time: starttime,
          used_room: used_room.length,
          used_room_M: used_room_M.length,
          used_room_F: used_room_F.length,
        }
        self.count_room_list.push(count_roomInfo)
      }
      self.refreshDataGrid()
    },
    get_count_name(data){
      var self = this
      const offset = data.date.getTimezoneOffset()
      var offsettime = new Date(data.date.getTime() - (offset*60*1000))
      var hour =  offsettime.toISOString().split('T')[1].substring(0, 2)
      var minutes =  offsettime.toISOString().split('T')[1].substring(3, 5)
      var starttime = hour+':'+minutes
      if(!data.used_room_M || !data.used_room_F || !data.used_room){
        if(self.count_room_list.length > 0){
          var list = self.count_room_list.filter(function (item) {
            return item.time == starttime
          })
          if(!data.used_room_M){
            data.used_room_M = list[0].used_room_M+'/'+self.room_list_M.length
          }
          if(!data.used_room_F){
            data.used_room_F = list[0].used_room_F+'/'+self.room_list_F.length
          }
          if(!data.used_room){
            data.used_room = list[0].used_room+'/'+self.room_list.length
          }
        }
      }
      return data.text
    },
  },
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style>
.modal {
  top: -20px !important;
}

.dp__menu {
  
}

.dx-scheduler{
  height: calc(100vh - 115px) !important;
}

.dx-scheduler-cell-sizes-vertical {  
  height: 70px !important;
}

.dx-scheduler-cell-sizes-horizontal{
  width: 220px !important;
  height: 70px !important;
}

.dx-scheduler-appointment {
  color: black !important;
  border-left: 0px !important;
}

.dx-scheduler-appointment-content {
  padding: 0px 0px !important;
}

.disable-date {
  background-image: repeating-linear-gradient( 135deg, rgba(244, 67, 54, 0.2), rgba(244, 67, 54, 0.2) 4px, transparent 4px, transparent 9px );
  font-size: 16px !important;
}


.genderM {
  border-left: 10px solid #2881ed !important;
  border-top: 10px solid #2881ed !important;
}

.genderF {
  border-left: 10px solid #d4264f !important;
  border-top: 10px solid #d4264f !important;
}

.genderMBG{
  background-color: #a9e7f3 !important;
}

.genderMBG_phone{
  margin-top:10px !important;
  padding :10px !important;
  border-radius: 20px !important;
  background-color: #a9e7f3 !important;
}

.genderFBG_phone{
  margin-top:10px !important;
  padding :10px !important;
  border-radius: 20px !important;
  background-color: #ffdee1 !important;
}

.defaultBG_phone{
  margin-top:10px !important;
  padding :10px !important;
  border-radius: 20px !important;
  background-color: #c3c2c2 !important;
}

.newJoin {
  background-color: #ebeb38;
}

.CallTeam {
  background-color: #50d43a;
}

.WTSTeam {
  background-color: #ff9f55;
}

.fa-whatsapp  {
  color:#fff;
  background:
   linear-gradient(#25d366,#25d366) 14% 84%/16% 16% no-repeat,
   radial-gradient(#25d366 60%,transparent 0);
}

.fa-phone  {
  color: rgb(4, 97, 66);
}

.fa-star  {
  color: #c9c930;
}

.drag {
  box-sizing: border-box;
  padding: 3px 5px;
  margin-bottom: 5px;
}

@keyframes fade {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1.0;
  }
}
@-webkit-keyframes fade {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1.0;
  }
}
.without_therapist {
  color: red;
  font-size: 16px;
  animation: fade 600ms infinite;
  -webkit-animation: fade 600ms infinite;
}

.need_update {
  color: red;
  animation: fade 600ms infinite;
  -webkit-animation: fade 600ms infinite;
}
</style>