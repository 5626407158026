<template>
  <CToaster placement="top-end">
    <CToast autohide="5000" :class="toast.cus_class" v-for="toast in toasts" :key="toast.content">
      <CToastHeader closeButton>
        <svg
          class="rounded me-2"
          width="20"
          height="20"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
          role="img"
        >
          <rect width="100%" height="100%" fill="#41c847"></rect>
        </svg>
        <span class="me-auto fw-bold">{{ toast.data.nickname }} ({{ toast.data.bookingDate }} {{ toast.data.start_time }}-{{ toast.data.end_time}})</span>
      </CToastHeader>
      <CToastBody><span class="me-auto fw-bold" style="color:#404345;">{{ toast.content }}</span></CToastBody>
    </CToast>
  </CToaster>
  <b-alert v-show="version_update" show variant="warning">有新的系統版本! 5秒後將重新載入</b-alert>
  <!-- Modal for Authorization-->
  <div>
    <b-button
      id="confirmModal_open"
      variant="outline-success"
      style="display: none"
      data-bs-toggle="modal"
      data-bs-target="#confirmModal"
      >{{ $t('common.button.confirm') }}</b-button
    >
  </div>
  <div
    id="confirmModal"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="confirmModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="confirmModalLabel" class="modal-title"></h5>
          <button
            id="confirmModal_close"
            type="button"
            style="display: none"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <span>{{ $t('common.master.pass_code') }}</span>
              <CFormInput
                id="staff_code"
                v-model="staff_code"
                size="sm"
                type="password"
                @keyup.enter="staff_code_check()"
              />
            </div>
          </div>
          <div v-if="!vaild_staff_code && !staff_id && allowed" class="row">
            <div class="col-12">
              <span class="text-danger">{{
                $t('common.message.staff_code_error')
              }}</span>
            </div>
          </div>
          <div v-if="!vaild_staff_code && staff_id">
            <div class="row">
              <div class="col-12">
                <span class="text-danger">{{
                  $t('common.message.permission_denied')
                }}</span>
              </div>
            </div>
          </div>
          <div v-if="!vaild_staff_code && !allowed" class="row">
            <div class="col-12">
              <h4 style="color: red;">請使用公司網絡!!!</h4>
            </div>
          </div>
          <div class="row" v-if="staff_code_checked == false">
            <div class="col-12">
              <!--
              <b-button
                variant="secondary"
                href="./#/Dashboard"
                @click="home_page()"
              >
                {{ $t('common.button.home') }}
              </b-button>
              -->
              輸入後按Enter/提交繼續
            </div>
          </div>
          <div>
            <b-button variant="outline-success" @click="staff_code_check()">{{
              $t('common.button.submit')
            }}</b-button>
          </div>
        </div>
        <div class="modal-footer" style="display: none">
          <b-button variant="outline-success" @click="submit()">{{
            $t('common.button.submit')
          }}</b-button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Authorization-->

  <!-- Modal for Request-->
  <div>
    <b-button
      id="requestModal_open"
      variant="outline-success"
      style="display: none"
      data-bs-toggle="modal"
      data-bs-target="#requestModal"
      >{{ $t('common.button.confirm') }}</b-button
    >
  </div>
  <div
    id="requestModal"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="requestModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="requestModalLabel" class="modal-title">技術支援</h5>
          <button
            id="requestModal_close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <span style="color:red;">請輸入問題 / 要求。IT部將稍後處理。</span>
              <br>
              <CFormInput
                v-model="request_text"
                size="sm"
                @keyup.enter="action_auth('request')"
              />
            </div>
          </div>
          <div>
            <b-button variant="outline-success" @click="action_auth('request')">{{
              $t('common.button.submit')
            }}</b-button>
          </div>
        </div>
        <div class="modal-footer" style="display: none">
          <b-button variant="outline-success" @click="submit()">{{
            $t('common.button.submit')
          }}</b-button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Request-->

  <!-- Modal for Timer-->
  <div>
    <b-button
      id="timerModal_open"
      variant="outline-success"
      style="display: none"
      data-bs-toggle="modal"
      data-bs-target="#timerModal"
      >{{ $t('common.button.confirm') }}</b-button
    >
  </div>
  <div
    id="timerModal"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="timerModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="timerModalLabel" class="modal-title">儀器超時</h5>
          <button
            id="timerModal_close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="close_timer()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <span style="color:red;">請處理以下超時儀器。</span>
              <br>
            </div>
          </div>
          <br>
          <template v-for="item in timeout_list" :key="item.id">
            <div class="row">
              <div class="col-6 text-center">
                <span>
                  儀器: {{ getlistitem('machine_sku', item.machine_id) }}
                </span>
              </div>
              <div class="col-6 text-center need_update">
                <span>
                  房間: {{ getlistitem('room', item.room_id) }}
                </span>
              </div>
            </div>
          </template>
        </div>
        <div class="modal-footer" style="display: none">
          <b-button variant="outline-success" @click="submit()">{{
            $t('common.button.submit')
          }}</b-button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Timer-->

  <b-overlay :show="show" rounded="sm">
    <div class="row">
      <div class="col-4 text-left"></div>
      <div class="col-4">
        <div class="flex-row justify-content-between">
          <div>
            <span style="color:green;">預約表 ({{ branch }})</span>
          </div>
        </div>
      </div>
      <div class="col-4 text-right">
        <span style="font-size: 13px;">Version {{ version }}&nbsp;</span>
      </div>
    </div>

    <div id="fullscreen" class="bg-white">
      <!-- Modal for Event -->
      <div
        id="eventModal"
        class="modal fade"
        data-bs-keyboard="false"
        tabindex="-1"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-center">
              <span v-if="eventAction == 'create'">新預約</span>
              <span v-else>修改預約 (Booking ID:{{ eventInfo.id }})</span>
              &nbsp;&nbsp;
              <b-button
                class="mx-1 position-relative"
                size="sm"
                variant="outline-secondary"
                data-bs-toggle="offcanvas"
                data-bs-target="#availbaleoffcanvas2"
                @click="getAvailableTherapistList2()"
              >
                <span>該時段可預約的理療師</span>
              </b-button>
            </h5>
            <b-button
              variant="danger"
              @click="action_request()"
              v-if="eventAction != 'create'"
            >
              <span>技術支援</span>
            </b-button>
          </div>
          <div class="modal-body">
            <div v-if="eventInfo">
              <div v-if="eventInfo.special_event > 0">
                <div class="row">
                  <div class="col-3 text-left">
                  </div>
                  <div class="col-3 text-center">
                    <span>類型</span>
                  </div>
                  <div class="col-3 text-left">
                    <Multiselect
                        v-model="eventInfo.special_event"
                        mode="single"
                        :close-on-select="true"
                        :searchable="false"
                        :create-option="false"
                        :options="special_event_list"
                        :allow-empty="false"
                        :canClear="false"
                        :canDeselect="false"
                        valueProp="id"
                        trackBy="id"
                        label="event"
                      />
                  </div>
                  <div class="col-3 text-left">
                    <b-form-checkbox
                      v-if="eventInfo.special_event == 2"
                      v-model="allday"
                      value="1"
                      unchecked-value="0"
                    >
                      <span id="allday">全日</span>
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3 text-left"></div>
                  <div class="col-3 text-center">
                    <span>備註</span>
                  </div>
                  <div class="col-6 text-left">
                    <CFormInput
                      id="remark"
                      ref="remark"
                      v-model="eventInfo.remark"
                      size="sm"
                      type="text"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-3 text-left">
                  </div>
                  <div class="col-3 text-center">
                    <span>理療師</span>
                  </div>
                  <div class="col-3 text-left">
                    <div v-if="eventInfo.id > 0">
                      <span>{{ getlistitem('therapist', eventInfo.org_therapist) }}</span>
                    </div>
                    <div v-else>
                      <Multiselect
                        v-model="eventInfo.org_therapist"
                        mode="tags"
                        :close-on-select="false"
                        :searchable="true"
                        :create-option="false"
                        :options="therapist_list"
                        :allow-empty="false"
                        :canClear="false"
                        valueProp="id"
                        trackBy="nickname"
                        label="nickname"
                      />
                    </div>
                  </div>
                  <div class="col-3 text-left">
                    <b-form-checkbox
                      v-model="allstaff"
                      value="1"
                      unchecked-value="0"
                      v-if="eventInfo.id == 0"
                    >
                      <span id="allstaff">全部理療師</span>
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3 text-left">
                  </div>
                  <div class="col-3 text-center">
                    <span>開始時間</span>
                  </div>
                  <div class="col-3 text-left">
                    <datepicker
                      v-model="eventInfo.bookingStartTime"
                      size="sm"
                      :clearable="false"
                      timePicker
                      minutesGridIncrement="30"
                      :min-time="{ hours: 11}"
                      :max-time="{ hours: 22, minutes: 30}"
                      auto-apply
                    />
                  </div>
                  <div class="col-3 text-left">
                  </div>
                </div>
                <div class="row">
                  <div class="col-3 text-left">
                  </div>
                  <div class="col-3 text-center">
                    <span>結束時間</span>
                  </div>
                  <div class="col-3 text-left">
                    <datepicker
                      v-model="eventInfo.bookingEndTime"
                      size="sm"
                      :clearable="false"
                      timePicker
                      minutesGridIncrement="30"
                      :min-time="{ hours: 11}"
                      :max-time="{ hours: 22, minutes: 30}"
                      auto-apply
                    />
                  </div>
                  <div class="col-3 text-left">
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="row">
                  <div class="col-3 text-left">
                    <span>會員編號</span>
                  </div>
                  <div class="col-3 text-left">
                    <span>名稱</span>
                  </div>
                  <div class="col-3 text-left">
                    <span>別名</span>
                  </div>
                  <div class="col-3 text-left">
                    <span>性別</span>
                  </div>
                </div>
                <div>
                  <div class="row">
                    <div class="col-3 text-left">
                      <CFormInput
                        id="member_no"
                        ref="member_no"
                        v-model="eventInfo.member_no"
                        size="sm"
                        type="text"
                        @change="member_check(eventInfo)"
                        :readonly="eventAction == 'edit'"
                      />
                    </div>
                    <div class="col-3 text-left">
                      <span :style="vaild_member ? '' : 'color:red;'">{{ eventInfo.name }}</span>
                    </div>
                    <div class="col-3 text-left">
                      <CFormInput
                      id="nickname"
                      ref="nickname"
                      v-model="eventInfo.nickname"
                      size="sm"
                      type="text"
                    />
                    </div>
                    <div class="col-3 text-left">
                      <b-form-radio-group
                        v-model="eventInfo.gender"
                        :options="gender_list"
                        value-field="value"
                        text-field="name"
                        disabled-field="notEnabled"
                        @change="room_check()"
                      ></b-form-radio-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4 text-left">
                      <span>預約日期</span>
                    </div>
                    <div class="col-4 text-left">
                      <span>開始時間</span>
                    </div>
                    <div class="col-4 text-left">
                      <span>結束時間</span>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-4 text-left">
                      <datepicker
                        v-model="eventInfo.bookingDate"
                        size="sm"
                        auto-apply
                        input-format="yyyy-MM-dd"
                        :clearable="false"
                        :enable-time-picker="false"
                        @update:modelValue="change_bookingDate(eventInfo)"
                        :readonly="eventInfo.checkout_time"
                      />
                    </div>
                    <div class="col-4 text-left">
                      <datepicker
                        v-model="eventInfo.bookingStartTime"
                        size="sm"
                        :clearable="false"
                        timePicker
                        minutesGridIncrement="30"
                        :min-time="{ hours: 11}"
                        :max-time="{ hours: 22, minutes: 30}"
                        auto-apply
                        @update:modelValue="change_bookingStartTime()"
                        :readonly="eventInfo.checkout_time"
                      />
                    </div>
                    <div class="col-4 text-left">
                      <datepicker
                        v-model="eventInfo.bookingEndTime"
                        size="sm"
                        :clearable="false"
                        timePicker
                        minutesGridIncrement="30"
                        :min-time="{ hours: 11}"
                        :max-time="{ hours: 22, minutes: 30}"
                        auto-apply
                        @update:modelValue="change_bookingEndTime()"
                        :readonly="eventInfo.checkout_time"
                      />
                    </div>
                  </div>
                  <div class="row" v-show="full_room">
                    <div class="col-12 text-left">
                      <span style="color:red;">{{ full_room }}</span>
                    </div>
                  </div>
                  <div class="row" v-if="eventAction == 'edit' && eventInfo.created_from != '1'">
                    <div class="col-6 text-left">
                      <span>Call Team備註: {{ eventInfo.remark_call }}</span>
                    </div>
                    <div class="col-6 text-left">
                      <span>種類: {{ getlistitem('member_type', eventInfo.member_type) }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 text-left">
                      <span>備註</span>
                    </div>
                    <div class="col-6 text-left">
                      <span>內部備註 (不會顯示在電話版面)</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 text-left">
                      <CFormInput
                        id="remark"
                        ref="remark"
                        v-model="eventInfo.remark"
                        size="sm"
                        type="text"
                      />
                    </div>
                    <div class="col-6 text-left">
                      <CFormInput
                        id="remark_internal"
                        ref="remark_internal"
                        v-model="eventInfo.remark_internal"
                        size="sm"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 text-left">
                      <span>理療師</span>
                    </div>
                    <div class="col-6 text-left">
                      <span></span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3 text-left">
                      <Multiselect
                        v-model="eventInfo.org_therapist"
                        mode="tags"
                        :close-on-select="false"
                        :searchable="true"
                        :create-option="false"
                        :options="therapist_list"
                        :allow-empty="false"
                        :canClear="false"
                        valueProp="id"
                        trackBy="nickname"
                        label="nickname"
                        @input="therapist_check()"
                        :disabled="eventInfo.checkout_time"
                      />
                    </div>
                    <div class="col-3">
                      <b-form-checkbox
                        v-model="eventInfo.new_join"
                        value="1"
                        unchecked-value="0"
                        @change="therapistOption('new_join')"
                      >
                        <span>新會員</span>
                      </b-form-checkbox>
                    </div>
                    <div class="col-3">
                      <b-form-checkbox
                        v-model="eventInfo.specify"
                        value="1"
                        unchecked-value="0"
                        @change="therapistOption('specify')"
                      >
                        <span>指定理療師</span>
                      </b-form-checkbox>
                    </div>
                    <div class="col-3">
                      <b-form-checkbox
                        v-model="eventInfo.recommend"
                        value="1"
                        unchecked-value="0"
                        @change="therapistOption('recommend')"
                      >
                        <span>推介理療師</span>
                      </b-form-checkbox>
                    </div>
                  </div>
                  <div class="row" v-show="therapist_booked">
                    <div class="col-12 text-left">
                      <span style="color:red;">{{ therapist_booked }}在該時段已有booking</span>
                      <b-form-checkbox
                        v-model="update_sync_delete"
                        value="1"
                        unchecked-value="0"
                      >
                        <span>更新時將{{ therapist_booked }}在該時段的原有Booking轉為</span><span style="color:red;">舊客Waiting List</span>
                      </b-form-checkbox>
                    </div>
                  </div>
                  <div class="row" v-if="eventAction == 'edit'">
                    <div class="col-6 text-left">
                      <span>Re Confirm</span>
                    </div>
                    <div class="col-4 text-left">
                      <span>到達時間</span>&nbsp;
                      <b-button
                        v-if="!eventInfo.checkout_time"
                        variant="secondary"
                        @click="arrival_now()"
                      >
                        <span>Now</span>
                      </b-button>
                    </div>
                  </div>
                  <div class="row" v-if="eventAction == 'edit'">
                    <div class="col-6 text-left">
                      <b-form-radio-group
                        v-model="eventInfo.reconfirm"
                        :options="reconfirm_list"
                        value-field="value"
                        text-field="name"
                        disabled-field="notEnabled"
                      ></b-form-radio-group>
                    </div>
                    <div class="col-4 text-left">
                      <datepicker
                        v-model="eventInfo.arrivalTime"
                        size="sm"
                        :clearable="false"
                        timePicker
                        minutesGridIncrement="5"
                        auto-apply
                        :readonly="eventInfo.checkout_time"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4 text-left">
                      <span>營養師</span>
                    </div>
                    <div class="col-4 text-left">
                      <span>中醫師</span>
                    </div>
                    <div class="col-4 text-left">
                      <span>儀器機</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4 text-left">
                      <Multiselect
                        v-model="eventInfo.nutritionist"
                        mode="tags"
                        :close-on-select="false"
                        :searchable="false"
                        :create-option="false"
                        :options="nutritionist_list"
                        :allow-empty="false"
                        valueProp="id"
                        trackBy="nickname"
                        label="nickname"
                      />
                    </div>
                    <div class="col-4 text-left">
                      <Multiselect
                        v-model="eventInfo.chinese_medicine"
                        mode="tags"
                        :close-on-select="false"
                        :searchable="true"
                        :create-option="false"
                        :options="chinese_medicine_list"
                        :allow-empty="false"
                        valueProp="id"
                        trackBy="nickname"
                        label="nickname"
                      />
                    </div>
                    <div class="col-4 text-left">
                      <Multiselect
                        v-model="eventInfo.machine"
                        mode="tags"
                        :close-on-select="false"
                        :searchable="true"
                        :create-option="false"
                        :options="machine_list"
                        :allow-empty="false"
                        valueProp="id"
                        trackBy="machine"
                        label="machine"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4 text-left">
                      <span>運動科學顧問</span>
                    </div>
                    <div class="col-3 text-left">
                      <span>會員類別</span>
                    </div>
                    <div class="col-4 text-left">
                      <span></span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4 text-left">
                      <Multiselect
                        v-model="eventInfo.org_sports_science_consultant"
                        mode="tags"
                        :close-on-select="false"
                        :searchable="true"
                        :create-option="false"
                        :options="sports_science_consultant_list"
                        :allow-empty="false"
                        valueProp="id"
                        trackBy="nickname"
                        label="nickname"
                      />
                    </div>
                    <div class="col-3 text-left">
                      <Multiselect
                        v-model="eventInfo.sports_science_type"
                        mode="single"
                        :close-on-select="true"
                        :searchable="true"
                        :create-option="false"
                        :options="sports_science_type_list"
                        :allow-empty="false"
                        valueProp="id"
                        trackBy="name"
                        label="name"
                      />
                    </div>
                    <div class="col-4 text-left"></div>
                  </div>
                  <div class="row">
                    <div class="col-4 text-left" v-if="eventInfo.checkin_time">
                      <span>Check-in時間</span>
                    </div>
                    <div class="col-3 text-left">
                      <span>房號</span>
                    </div>
                    <div class="col-3 text-left" v-show="eventAction == 'edit' && eventInfo.status == 1 && (eventInfo.arrival_time && !eventInfo.checkin_time) && eventInfo.org_therapist.length > 1">
                      <span></span>
                    </div>
                    <div class="col-3 text-left" v-show="eventAction == 'edit' && eventInfo.status == 1 && (eventInfo.arrival_time && !eventInfo.checkin_time)">
                      <span></span>
                    </div>
                    <div class="col-3 text-left">
                      <span>顧問</span>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <b-form-checkbox
                        style="display: inline-block !important;"
                        v-model="eventInfo.without_advisor"
                        value="1"
                        unchecked-value="0"
                      >
                        <span>不輪</span>
                      </b-form-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4 text-left" v-if="eventInfo.checkin_time">
                      <span>{{ eventInfo.checkin_date }} {{ eventInfo.checkin_time }}</span>
                      <span v-show="eventInfo.without_therapist == 1" style="color:red;">&nbsp;&nbsp;未有理療師</span>
                      <span v-show="eventInfo.without_therapist == 2" style="color:red;">&nbsp;&nbsp;有部份理療師</span>
                    </div>
                    <div class="col-3 text-left">
                      <Multiselect
                        v-model="eventInfo.room"
                        mode="single"
                        :close-on-select="true"
                        :searchable="false"
                        :create-option="false"
                        :options="room_list"
                        :allow-empty="false"
                        valueProp="id"
                        trackBy="room"
                        label="room"
                      />
                    </div>
                    <div class="col-3 text-left" v-show="eventAction == 'edit' && eventInfo.status == 1 && (eventInfo.arrival_time && !eventInfo.checkin_time) && eventInfo.org_therapist.length > 1">
                      <b-form-checkbox
                        v-model="eventInfo.without_therapist"
                        value="2"
                        unchecked-value="0"
                      >
                        <span>部份理療師</span>
                      </b-form-checkbox>
                    </div>
                    <div class="col-3 text-left" v-show="eventAction == 'edit' && eventInfo.status == 1 && (eventInfo.arrival_time && !eventInfo.checkin_time)">
                      <b-form-checkbox
                        v-model="eventInfo.without_therapist"
                        value="1"
                        unchecked-value="0"
                      >
                        <span>房間沒有理療師</span>
                      </b-form-checkbox>
                    </div>
                    <div class="col-3 text-left">
                      <Multiselect
                        v-model="eventInfo.advisor"
                        mode="tags"
                        :close-on-select="false"
                        :searchable="false"
                        :create-option="false"
                        :options="advisor_list"
                        :allow-empty="false"
                        valueProp="id"
                        trackBy="nickname"
                        label="nickname"
                      />
                    </div>
                  </div>
                  <div class="row" v-if="eventInfo.checkout_time">
                    <div class="col-4 text-left">
                      <span>Check-out時間</span>
                    </div>
                  </div>
                  <div class="row" v-if="eventInfo.checkout_time">
                    <div class="col-4 text-left">
                      <span>{{ eventInfo.checkout_date }} {{ eventInfo.checkout_time }}</span>
                    </div>
                  </div>
                  <div class="row" v-if="eventInfo.cancel_reason">
                    <div class="col-12 text-left">
                        <span style="color:red;">取消原因: {{ getlistitem('cancel_reason', eventInfo.cancel_reason) }}</span><span v-if="eventInfo.cancel_reason == 8"> ({{eventInfo.cancel_reason_remark}})</span>
                    </div>
                  </div>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-12 text-center">
                  <!-- Cancel button -->
                  <b-button
                    variant="danger"
                    @click="cancel_booking()"
                    v-if="eventAction == 'edit' && eventInfo.status == 1 && !eventInfo.arrival_time && role_check(14) && !role_check(29)"
                  >
                    <span>取消預約</span>
                  </b-button>
                  <span v-if="eventAction == 'edit' && eventInfo.status == 1 && !eventInfo.arrival_time">&nbsp;&nbsp;</span>
                  <!-- End cancel button -->

                  <!-- Back button -->
                  <b-button
                    variant="secondary"
                    @click="back()"
                  >
                    {{ $t('common.button.back') }}
                  </b-button>
                  <span>&nbsp;&nbsp;</span>
                  <!-- End back button -->

                  <!-- Submit button -->
                  <b-button v-if="((eventInfo.member_no && vaild_member) || eventInfo.special_event > 0) && eventInfo.org_therapist.length > 0 && (eventInfo.bookingStartTime.hours != eventInfo.bookingEndTime.hours || eventInfo.bookingStartTime.minutes != eventInfo.bookingEndTime.minutes) && !eventInfo.checkout_date && role_check(14) && !role_check(29)" variant="success" @click="action_auth('submit')">
                    <span v-if="eventInfo.id == 0">{{ $t('common.button.submit') }}</span>
                    <span v-if="eventInfo.id > 0">{{ $t('common.button.update') }}</span>
                    <span v-if="eventInfo.status != 1">{{ $t('common.button.booking') }}</span>
                  </b-button>

                  <!-- 後補 button -->
                  <b-button
                    variant="success"
                    @click="action_auth('submit')"
                    v-if="eventAction == 'edit' && eventInfo.status == 1 && eventInfo.checkout_time && eventInfo.without_therapist == '0'"
                  >
                    <span>後補資料</span>
                  </b-button>
                  <!-- End 後補 button -->

                  <span v-if="(eventInfo.member_no || eventInfo.special_event > 0) && !eventInfo.checkout_date">&nbsp;&nbsp;</span>
                  <!-- End submit button -->

                  <!-- Muti-Submit button -->
                  <b-button v-if="eventInfo.member_no && vaild_member && eventInfo.id == 0 && eventInfo.org_therapist.length > 0 && (eventInfo.bookingStartTime.hours != eventInfo.bookingEndTime.hours || eventInfo.bookingStartTime.minutes != eventInfo.bookingEndTime.minutes) && !eventInfo.checkout_date" variant="primary" @click="action_auth('submit_continue')">
                    <span>{{ $t('common.button.submit') }}及繼續預約</span>
                  </b-button>
                  <!-- End submit button -->
                  
                  <!-- Check-in button -->
                  <b-button
                    variant="warning"
                    @click="action_auth('checkin')"
                    v-if="eventAction == 'edit' && eventInfo.status == 1 && (eventInfo.arrival_time && !eventInfo.checkin_time) && role_check(14) && !role_check(29)"
                  >
                    <span>Check-in</span>
                  </b-button>
                  <span v-if="eventAction == 'edit' && eventInfo.status == 1 && (eventInfo.arrival_time && !eventInfo.checkin_time)">&nbsp;&nbsp;</span>
                  <!-- End check-in button -->

                  <!-- Therapist Check-in button -->
                  <b-button
                    variant="warning"
                    @click="action_auth('therapist_checkin')"
                    v-if="eventAction == 'edit' && eventInfo.status == 1 && (eventInfo.without_therapist == '1' || eventInfo.without_therapist == '2') && eventInfo.checkin_time && role_check(14) && !role_check(29)"
                  >
                    <span>理療師進入房間</span>
                  </b-button>
                  <span v-if="eventAction == 'edit' && eventInfo.status == 1 && (eventInfo.without_therapist == '1' || eventInfo.without_therapist == '2') && eventInfo.checkin_time">&nbsp;&nbsp;</span>
                  <!-- End Therapist check-in button -->
                  
                  <!-- Check-out button -->
                  <b-button
                    variant="warning"
                    @click="action_auth('checkout')"
                    v-if="eventAction == 'edit' && eventInfo.status == 1 && (eventInfo.checkin_time && !eventInfo.checkout_time) && eventInfo.without_therapist == '0' && role_check(14) && !role_check(29)"
                  >
                    <span>Check-out</span>
                  </b-button>
                  <!-- End check-out button -->
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" style="display: none">
            <button
              id="eventModal_close"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
        </div>
      </div>
    </div>
    <b-button
      id="eventModal_open"
      variant="outline-success"
      style="display: none"
      data-bs-toggle="modal"
      data-bs-target="#eventModal"
      >{{ $t('common.button.confirm') }}
    </b-button>
    <!-- End of Modal for Event -->

    <!-- Confirm delete modal-->
    <div>
      <b-modal
        v-model="modalShow"
        class="text-secondary mb-0"
        hide-footer
        :title="$t('common.message.warning')"
      >
        <div>
          <div class="card-header pb-0">
            <div class="d-flex flex-row justify-content-between">
              <div v-if="modalShow">
                <h5 class="text-delete-warning">
                  {{ $t('common.button.confirm') }}取消預約？
                </h5>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-left">
                <Multiselect
                  v-model="eventInfo.cancel_reason"
                  mode="single"
                  :close-on-select="true"
                  :searchable="false"
                  :create-option="false"
                  :options="cancel_reason_list"
                  :allow-empty="false"
                  :canClear="true"
                  valueProp="id"
                  trackBy="name_tc"
                  label="name_tc"
                  placeholder="請選擇取消原因"
                />
              </div>
            </div>
            <div v-if="eventInfo.cancel_reason == 8" class="row">
              <div class="col-12 text-left">
                <CFormInput
                  v-model="eventInfo.cancel_reason_remark"
                  type="text"
                  placeholder="其他原因"
                />
              </div>
            </div>
            <br>
            <div class="d-flex flex-row justify-content-between">
              <div></div>
              <div>
                <b-button variant="secondary" @click="cancel_delete()">{{
                  $t('common.button.cancel')
                }}</b-button>
                &nbsp;
                <b-button
                  variant="danger"
                  @click="action_auth('confirm_delete')"
                  >{{ $t('common.button.confirm') }}</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <!-- End of delete modal-->

    <!-- Search Booking -->
    <div class="offcanvas offcanvas-end offcanvas-width" tabindex="-1" id="searchbookingoffcanvas">
      <div class="offcanvas-header">
        <h5 id="offcanvasTopLabel">會員編號{{ member_no }}-Booking記錄</h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" @click="back_search()"></button>
      </div>
      <div class="offcanvas-body">
        <div v-if="booking_history.length > 0">
          <div class="row" v-show="history_today">
            <div class="col-12 text-left">
              <b-button
                v-show="history_today"
                variant="secondary"
                @click="history_today = false"
              >
                顯示全部記錄
              </b-button>
            </div>
          </div>
          <br>
          <template v-for="item in getHistoryList" :key="item.id">
            <div class="row">
              <div class="col-3 text-left">
                <span>預約日期:</span>
              </div>
              <div class="col-5 text-left">
                <span>{{ item.booking_date }}</span>
                <span style="color:red;" v-if="item.status == -1"> (已取消)</span>
              </div>
              <div class="col-4 text-right">
                <b-button
                  size="sm"
                  variant="outline-success"
                  @click="booking_click(item)"
                  >
                  <span>{{ $t('common.button.view') }}</span>
                </b-button>
                &nbsp;
                <b-button
                  size="sm"
                  variant="outline-success"
                  @click="getBookingHistory(item)"
                  v-if="role_check(17)"
                  >
                  <span>{{ $t('common.button.history') }}</span>
                </b-button>
              </div>
            </div>
            <div class="row">
              <div class="col-3 text-left">
                <span>預約時間:</span>
              </div>
              <div class="col-9 text-left">
                <span>{{ item.start_time }}-{{ item.end_time }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-3 text-left">
                <span>理療師:</span>
              </div>
              <div class="col-9 text-left">
                <span>{{ getlistitem('therapist', item.org_therapist) }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-3 text-left">
                <span>建立時間:</span>
              </div>
              <div class="col-9 text-left">
                <span>{{ item.created_at.substring(0, 10) }} {{ item.created_at.substring(11, 19) }} ({{ item.created_by_nickname }})</span>
              </div>
            </div>
            <hr>
          </template>
        </div>
        <div v-else class="row">
          <div class="col-12 text-center">
            <span style="color:red;">沒有Booking記錄</span>
          </div>
        </div>
      </div>
    </div>
    <!-- End of Search Booking -->

    <!-- Timer -->
    <div class="offcanvas offcanvas-start offcanvas-width4" tabindex="-1" id="timeroffcanvas">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title text-center">儀器計時 {{ currentTime }}</h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body text-left">
        <template v-for="item in timer_list" :key="item.id">
          <div class="row">
            <div class="col-8 text-left">
              <span>
                儀器: {{ getlistitem('machine_sku', item.machine_id) }} ({{ getlistitem('room', item.room_id) }})
              </span>
            </div>
            <div class="col-4 text-right">
              <span>
                開始時間: {{ item.start_time.slice(0, 5) }}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-5 text-left">
              <span>
                預計結束時間: {{ item.estimate_end_time.slice(0, 5) }}
              </span>
            </div>
            <div class="col-7 text-right">
              <span>
                距離結束時間: 
              </span>
              <span style="color:red;">
                {{ item.remain }}
              </span>
            </div>
          </div>
          <hr>
        </template>
      </div>
    </div>
    <!-- End of Timer -->

    <!-- Available -->
    <div class="offcanvas offcanvas-start offcanvas-width3" tabindex="-1" id="availbaleoffcanvas">
      <div class="offcanvas-header">
        <h5 id="offcanvasTopLabel">{{ today_date }}({{ currentTime }})<br>現可預約的理療師</h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" @click="back_search()"></button>
      </div>
      <div class="offcanvas-body">
        <div v-if="AvailableTherapistList.length > 0">
          <template v-for="item in AvailableTherapistList" :key="item.id">
            <div class="row text-left" v-if="item.available == true">
              <span>{{ getlistitem('therapist', item.id) }}</span>
            </div>
          </template>
        </div>
        <div v-else class="row">
          <div class="col-12 text-center">
            <span style="color:red;">沒有可預約的理療師</span>
          </div>
        </div>
      </div>
    </div>
    <!-- End of Available -->

    <!-- Available -->
    <div class="offcanvas offcanvas-start offcanvas-width3" tabindex="-1" id="availbaleoffcanvas2">
      <div class="offcanvas-header">
        <h5 id="offcanvasTopLabel">可預約的理療師</h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" @click="back_search()"></button>
      </div>
      <div class="offcanvas-body">
        <div v-if="AvailableTherapistList2.length > 0">
          <template v-for="item in AvailableTherapistList2" :key="item.id">
            <div class="row text-left" v-if="item.available == true">
              <span>{{ getlistitem('therapist', item.id) }}</span>
            </div>
          </template>
        </div>
        <div v-else class="row">
          <div class="col-12 text-center">
            <span style="color:red;">沒有可預約的理療師</span>
          </div>
        </div>
      </div>
    </div>
    <!-- End of Available -->

    <!-- Booking History -->
    <div class="offcanvas offcanvas-end offcanvas-width2" tabindex="-1" id="historyoffcanvas">
      <div class="offcanvas-header">
        <h5 id="offcanvasTopLabel">Booking Change Log</h5>
        <button type="button" class="btn-close text-reset" @click="back_history()"></button>
      </div>
      <div class="offcanvas-body">
        <div v-if="selected_history.length > 0">
          <template v-for="item in selected_history" :key="item.id">
            <div class="row">
              <div class="col-3 text-left">
                <span>會員編號:</span>
              </div>
              <div class="col-9 text-left">
                <span>{{ item.member_no }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-3 text-left">
                <span>預約日期:</span>
              </div>
              <div class="col-9 text-left">
                <span>{{ item.booking_date }} ({{ item.start_time }}-{{ item.end_time }})</span>
              </div>
            </div>
            <div class="row">
              <div class="col-3 text-left">
                <span>理療師:</span>
              </div>
              <div class="col-9 text-left">
                <span>{{ getlistitem('therapist', item.org_therapist) }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-3 text-left">
                <span>建立時間:</span>
              </div>
              <div class="col-9 text-left">
                <span>{{ item.created_at.substring(0, 10) }} {{ item.created_at.substring(11, 19) }} ({{ item.created_by_nickname }})</span>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-8 text-left">
                <b>修改內容</b>
              </div>
              <div class="col-4 text-right">
                <b>日期</b>
              </div>
            </div>
            <template v-if="item.history.length > 0" class="row">
              <template v-for="history in item.history" :key="history.id">
                <div class="row">
                  <div class="col-8 text-left">
                    <span v-show="history.from_data.nickname || history.to_data.nickname">別名: {{ history.from_data.nickname ? history.from_data.nickname+'->' : '新增' }}{{ history.to_data.nickname }}<br></span>
                    <span v-show="history.from_data.gender || history.to_data.gender">性別: {{ history.from_data.gender }}->{{ history.to_data.gender }}<br></span>
                    <span v-show="history.from_data.booking_date || history.to_data.booking_date">預約日期: {{ history.from_data.booking_date }}->{{ history.to_data.booking_date }}<br></span>
                    <span v-show="history.from_data.start_time || history.to_data.start_time">開始時間: {{ history.from_data.start_time }}->{{ history.to_data.start_time }}<br></span>
                    <span v-show="history.from_data.end_time || history.to_data.end_time">結束時間: {{ history.from_data.end_time }}->{{ history.to_data.end_time }}<br></span>
                    <span v-show="history.from_data.remark || history.to_data.remark">備註: {{ history.from_data.remark ? history.from_data.remark+'->' : '新增' }}{{ history.to_data.remark }}<br></span>
                    <span v-show="history.from_data.therapist || history.to_data.therapist">理療師: {{ getlistitem('therapist', history.from_data.therapist) }}->{{ getlistitem('therapist', history.to_data.therapist) }}<br></span>
                    <span v-show="history.to_data.recommend =='1'">推介理療師: 選擇<br></span>
                    <span v-show="history.to_data.specify =='1'">指定理療師: 選擇<br></span>
                    <span v-show="history.to_data.new_join =='1'">新會員: 選擇<br></span>
                    <span v-show="history.from_data.reconfirm || history.to_data.reconfirm">Re Confirm: {{ history.from_data.reconfirm ? history.from_data.reconfirm+'->' : '新增' }}{{ history.to_data.reconfirm }}<br></span>
                    <span v-show="history.from_data.arrival_time || history.to_data.arrival_time">到達時間: {{ history.from_data.arrival_time ? history.from_data.arrival_time+'->' : '新增' }}{{ history.to_data.arrival_time }}<br></span>
                    <span v-show="history.from_data.room || history.to_data.room">房號: {{ getlistitem('room', history.from_data.room) ? getlistitem('room', history.from_data.room)+'->' : '新增' }}{{ getlistitem('room', history.to_data.room) }}<br></span>
                    <span v-show="history.from_data.machine || history.to_data.machine">儀器機: {{ getlistitem('machine', history.from_data.machine) ? getlistitem('machine', history.from_data.machine)+'->' : '新增' }}{{ getlistitem('machine', history.to_data.machine) }}<br></span>
                    <span v-show="history.from_data.advisor || history.to_data.advisor">顧問: {{ getlistitem('advisor', history.from_data.advisor) ? getlistitem('advisor', history.from_data.advisor)+'->' : '新增' }}{{ getlistitem('advisor', history.to_data.advisor) }}<br></span>
                    <span v-show="history.from_data.nutritionist || history.to_data.nutritionist">營養師: {{ getlistitem('nutritionist', history.from_data.nutritionist) ? getlistitem('nutritionist', history.from_data.nutritionist)+'->' : '新增' }}{{ getlistitem('nutritionist', history.to_data.nutritionist) }}<br></span>
                    <span v-show="history.from_data.chinese_medicine || history.to_data.chinese_medicine">中醫師: {{ getlistitem('chinese_medicine', history.from_data.chinese_medicine) ? getlistitem('chinese_medicine', history.from_data.chinese_medicine)+'->' : '新增' }}{{ getlistitem('chinese_medicine', history.to_data.chinese_medicine) }}<br></span>
                    <span v-show="history.from_data.sports_science_consultant || history.to_data.sports_science_consultant">運動科學顧問: {{ getlistitem('sports_science_consultant', history.from_data.sports_science_consultant) ? getlistitem('sports_science_consultant', history.from_data.sports_science_consultant)+'->' : '新增' }}{{ getlistitem('sports_science_consultant', history.to_data.sports_science_consultant) }}<br></span>
                    <span v-show="history.to_data.sports_science_type == '1'">更改為首次會員<br></span>
                    <span v-show="history.to_data.sports_science_type == '2'">更改為療程會員<br></span>
                    <span v-show="history.from_data.created_by || history.to_data.created_by">建立者: {{ getlistitem('staff', history.from_data.created_by) }}->{{ getlistitem('staff', history.to_data.created_by) }}<br></span>
                    <span v-show="history.to_data.checkin_date">CHECK IN<br></span>
                    <span v-show="history.to_data.checkout_date">CHECK OUT<br></span>
                    <span v-show="history.to_data.status == '1'">重新預約<br></span>
                    <span v-show="history.to_data.status == '-1'">取消預約<br></span>
                    <span v-show="history.to_data.booking_show == '0'">取消Show<br></span>
                    <span v-show="history.to_data.booking_show == '1'">Show<br></span>
                    <span v-show="history.to_data.deal == '0'">取消Deal<br></span>
                    <span v-show="history.to_data.deal == '1'">Deal<br></span>
                    <span v-show="history.to_data.todo == '1'">已處理<br></span>
                  </div>
                  <div class="col-4 text-right">
                    <span>{{ history.created_at.substring(0, 10) }} {{ history.created_at.substring(11, 19) }}<br>{{ history.nickname }}</span>
                  </div>
                </div>
                <hr>
              </template>
            </template>
            <div v-else class="row">
              <div class="col-12 text-center">
                <span style="color:red;">沒有修改記錄</span>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- End of Booking History -->

    <div class="row">
      <div class="col-4 text-left">
        <b-button
          class="mx-1 position-relative"
          size="sm"
          variant="outline-secondary"
          data-bs-toggle="offcanvas"
          data-bs-target="#availbaleoffcanvas"
          @click="getAvailableTherapistList()"
        >
          <span>現可預約的理療師</span>
        </b-button>
        &nbsp;&nbsp;&nbsp;
        <b-button
          size="sm"
          :variant="timerfontcolor"
          data-bs-toggle="offcanvas"
          data-bs-target="#timeroffcanvas"
          >
            <span style="color:white;" :class="timer_list.length > 0 ? 'need_update2' : ''">{{ timer_list.length }} 個儀器正在使用中</span>
          </b-button
        >
      </div>
      <div class="col-4 text-center">
        <span>Follow Up模式</span>
      </div>
      <div class="col-2 text-right">
        <b-button
          id="searchbookingbutton"
          class="mx-1 position-relative"
          size="sm"
          variant="outline-secondary"
          data-bs-toggle="offcanvas"
          data-bs-target="#searchbookingoffcanvas"
          style="display: none"
        >
          <span>Roster</span>
        </b-button>
        <b-button
          id="bookinghistorybutton"
          class="mx-1 position-relative"
          size="sm"
          variant="outline-secondary"
          data-bs-toggle="offcanvas"
          data-bs-target="#historyoffcanvas"
          style="display: none"
        >
          <span>Roster</span>
        </b-button>
      </div>
      <div class="col-2 text-right">
        <b-button
          class="mx-1 position-relative"
          size="sm"
          variant="outline-warning"
          @click="get_api()"
          >
            <span :class="need_update ? 'need_update' : ''">{{ $t('common.button.update') }}</span>
            <b-badge v-show='need_update' variant="light" text-indicator style="transform: translate(-10%, -30%) !important; left:86% !important; border-radius: 1rem !important; font-size: 0.5rem !important;">
              <span style="color: red; font-size: 1rem !important;">!</span>
            </b-badge>
        </b-button>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-2 text-left">
        <datepicker
          v-model="currentDateFormat"
          size="sm"
          auto-apply
          input-format="yyyy-MM-dd"
          :clearable="false"
          :enable-time-picker="false"
          @update:modelValue="change_day()"
        />
      </div>
      <div class="col-1 text-left">
        <span>(星期{{date_day}})</span>
      </div>
      <div class="col-2 text-left">
        <CFormInput
          v-model="member_no"
          size="sm"
          type="text"
          @change="before_booking_check()"
          placeholder="電話/會員搜尋全部記錄"
        />
      </div>
      <div class="col-1 text-left">
      </div>
      <div class="col-2 text-left">
        <Multiselect
          v-model="filtersourceList.therapist"
          mode="single"
          :close-on-select="true"
          :searchable="false"
          :create-option="false"
          :options="therapist_list"
          :allow-empty="false"
          :canClear="true"
          valueProp="id"
          trackBy="nickname"
          label="nickname"
          placeholder="選擇理療師"
        />
      </div>
      <div class="col-2 text-right">
        <Multiselect
          v-model="filtersourceList.special"
          mode="single"
          :close-on-select="true"
          :searchable="false"
          :create-option="false"
          :options="special_list"
          :allow-empty="false"
          :canClear="false"
          :canDeselect="false"
          valueProp="id"
          trackBy="name"
          label="name"
          placeholder="請選擇"
        />
      </div>
      <div class="col-2 text-right">
        <CFormInput
          v-model="filtersourceList.freetext"
          type="text"
          placeholder="備註/會員搜尋當日記錄"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-10">
        <b-tabs
          active-nav-item-class="text-success"
        >
          <b-tab active @click="filtersourceList.sourceList = 1">
            <template #title>
              <strong>未到達({{ get_dataSourceList(1).length }})</strong>
            </template>
          </b-tab>
          <b-tab @click="filtersourceList.sourceList = 2">
            <template #title>
              <strong>已到達({{ get_dataSourceList(2).length }})</strong>
            </template>
          </b-tab>
          <b-tab @click="filtersourceList.sourceList = 5">
            <template #title>
              <strong>舊客Waiting List({{ get_dataSourceList(5).length }})</strong>
            </template>
          </b-tab>
          <b-tab @click="filtersourceList.sourceList = 3">
            <template #title>
              <strong>新客Waiting List({{ get_dataSourceList(3).length }})</strong>
            </template>
          </b-tab>
          <b-tab @click="filtersourceList.sourceList = 4">
            <template #title>
              <strong>取消({{ get_dataSourceList(4).length }})</strong>
            </template>
          </b-tab>
        </b-tabs>
      </div>
      <div class="col-2 text-right">
        <b-button
          class="mx-1 position-relative"
          pill
          variant="success"
          @click="showPopup()"
          style="font-size: 1.1rem;"
          v-if="role_check(14) && !role_check(29)"
        >
          <span>新增</span>
        </b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table-lite
          :is-slot-mode="true"
          :columns="getField"
          :rows="dataSourceList"
          :is-hide-paging="true"
        >
          <template #member="data">
            <div v-if="data.value.member_no" :class="data.value.gender == 'M' ? 'genderM genderMBG' : 'genderF genderFBG'">
              <span v-show="data.value.reconfirm == 'Phone'"><font-awesome-icon :icon="['fas', 'phone']" size="lg"/>&nbsp;</span>
              <span v-show="data.value.reconfirm == 'Whatsapp'"><font-awesome-icon :icon="['fab', 'whatsapp']" size="lg"/>&nbsp;</span>
              <span :class="data.value.new_join == '1' ? data.value.created_from == '1' ? 'newJoin' : data.value.created_from == '2' ? 'CallTeam' : 'WTSTeam' : ''">{{ data.value.member_no.toUpperCase() }}&nbsp;</span>
              <span :class="data.value.new_join == '1' ? data.value.created_from == '1' ? 'newJoin' : data.value.created_from == '2' ? 'CallTeam' : 'WTSTeam' : ''" v-show="data.value.nickname">({{ data.value.nickname }})&nbsp;</span>
              <span :class="data.value.new_join == '1' ? data.value.created_from == '1' ? 'newJoin' : data.value.created_from == '2' ? 'CallTeam' : 'WTSTeam' : ''" v-show="!data.value.nickname">({{ data.value.name }})&nbsp;</span>
              <span v-show="data.value.specify == '1'"><font-awesome-icon :icon="['fas', 'star']" size="lg"/></span>
              <span :class="data.value.new_join == '1' ? data.value.created_from == '1' ? 'newJoin' : data.value.created_from == '2' ? 'CallTeam' : 'WTSTeam' : ''" v-show="data.value.new_join == '1'"> (新會員)</span>
              <span class="newJoin" v-show="data.value.recommend == '1'"> (推)</span>
              <span :class="data.value.new_join == '1' ? data.value.created_from == '1' ? 'newJoin' : data.value.created_from == '2' ? 'CallTeam' : 'WTSTeam' : ''" v-show="data.value.member_type">&nbsp;({{ getlistitem('member_type', data.value.member_type) }})</span>
              <span v-show="data.value.org_therapist.length > 1" style="color:red; font-size: 20px;"> =</span>
            </div>
            <div v-else-if="data.value.special_event > 0">
              <span>{{ getlistitem('special_event', data.value.special_event) }}</span>
              <span v-show="data.value.remark">-{{data.value.remark}}</span>
            </div>
          </template>
          <template #booking_time="data">
            {{ data.value.start_time }}-{{ data.value.end_time }}
          </template>
          <template #arrival="data">
            <span v-if="data.value.arrival_time">{{ data.value.arrival_time }}</span>
            <span v-else :class="currentTime > data.value.start_time ? 'without_therapist' : ''">未到達</span>
          </template>
          <template #therapist="data">
            {{ getlistitem('therapist', data.value.therapist) }}
          </template>
          <template #remark="data">
            <span>{{ data.value.remark }}</span>
            <span style="font-size: 0.8em;" v-if="data.value.machine.length > 0"><br v-if="data.value.remark">儀器機: {{ getlistitem('machine', data.value.machine) }}</span>
          </template>
          <template #room="data">
            <div :class="data.value.without_therapist == '1' ? 'without_therapist' : ''">
              <span>{{ getlistitem('room', data.value.room) }}</span>
            </div>
          </template>
          <template #checkin="data">
            <span v-if="data.value.checkin_time">{{ data.value.checkin_time }}</span>
            <span v-else-if="data.value.arrival_time && !data.value.checkin_time && (today_date > data.value.bookingDate || getTimeDiff(data.value.arrival_time, '15'))" class="without_therapist">
              未Check In
            </span>
          </template>
          <template #checkout="data">
            <span v-if="data.value.checkout_time">{{ data.value.checkout_time }}</span>
            <span v-else-if="data.value.checkin_date && !data.value.checkout_date && (today_date > data.value.bookingDate || currentTime > data.value.end_time)" class="without_therapist">
              超過預計結束時間
            </span>
          </template>
          <template #cancel_reason="data">
            <span>{{ getlistitem('cancel_reason', data.value.cancel_reason) }}</span>
            <span v-show="data.value.cancel_reason_remark">-{{ data.value.cancel_reason_remark }}</span>
          </template>
          <template #created_from="data">
            <span v-if="data.value.created_from == '1'">分店</span>
            <span v-if="data.value.created_from != '1'">{{ getlistitem('staff', data.value.created_by) }}</span>
          </template>
          <template #action="data">
            <b-avatar button @click="booking_click(data.value)" variant="light" v-b-tooltip.hover title="更新">
              <span><font-awesome-icon :icon="['fas', 'pen']" size="lg"/></span>
            </b-avatar>
          </template>
        </table-lite>
      </div>
    </div>
    </div>
  </b-overlay>
</template>

<script>
export default {
  components: {
  },
  name: 'BookingList',
  data() {
    return {
      allowed: false,
      modalShow: false,
      need_update: false,
      version: '',
      version_update: false,
      branch_id: '',
      staff: '',
      staff_code: '',
      staff_id: '',
      staff_list: [],
      action_by: '',
      action_type: '',
      staff_code_checked: false,
      check_staff_code: true,
      vaild_staff_code: true,
      vaild_member: false,
      role: [],
      permission: [],
      member_no: '',
      selected_history: [],
      history_today: true,
      booking_history: [],
      currentDate: new Date(),
      currentDateFormat: '',
      endDateFormat: '',
      show: false,
      sourceList: 'active',
      filtersourceList: {
        sourceList: 1,
        freetext: '',
        therapist: '',
        special: 'all',
      },
      special_list: [
        { id: 'all', name: "全部" },
        { id: 'new_join', name: "新會員" },
        { id: 'specify', name: '指定理療師' },
        { id: 'recommend', name: '推介理療師' },
      ],
      cancel_reason_list: [],
      member_type_list: [],
      lang: '',
      eventAction: '',
      eventInfo: [],
      old_eventInfo: [],
      org_room_list: [],
      branch_list: [],
      room_list: [],
      machine_list: [],
      machine_sku_list: [],
      gender_list: [
        { value: 'F', name: "F" },
        { value: 'M', name: 'M' },
      ],
      reconfirm_list: [
        { value: 'None', name: '未確認' },
        { value: 'Phone', name: 'Phone' },
        { value: 'Whatsapp', name: 'Whatsapp' },
      ],
      AvailableTherapistList: [],
      AvailableTherapistList2: [],
      therapist_booked: '',
      full_room: '',
      bookingDatedataSource: [],
      roster_list: [],
      full_therapist_list: [],
      therapist_list: [
        {
          name: '舊客Waiting List',
          nickname: '舊客Waiting List',
          text: '舊客Waiting List',
          id: 9999,
          color: '#ededed',
        },
        {
          name: '新客Waiting List',
          nickname: '新客Waiting List',
          text: '新客Waiting List',
          id: 99999,
          color: '#ededed',
        }
      ],
      advisor_list: [],
      chinese_medicine_list: [],
      nutritionist_list: [],
      sports_science_consultant_list: [],
      sports_science_type_list: [
        {
          id: 1,
          name: '首次會員',
        },
        {
          id: 2,
          name: '療程會員',
        },
      ],
      tasks: [
        {
          text: '特別項目',
        }
      ],
      allday: 0,
      allstaff: 0,
      special_event_list: [],
      dataSource: [],
      currentTime: '',
      currentTime1: '',
      today_date: '',
      request_text: '',
      update_sync_delete: '0',
      booking_idList: [],
      orderBy: 'start_time',
      orderByFM: 'ASC',
      timerdialogcount: 0,
      timer_click: false,
      timer_list: [],
      timer_list_existed: false,
      timerfontcolor: 'success',
      edit_mode: false,
      toasts: [],
    }
  },
  computed: {
    dataSourceList: function () {
      return this.get_dataSourceList(this.filtersourceList.sourceList)
    },
    getHistoryList: function () {
      const self = this
      if(self.history_today == true){
        return self.booking_history.filter(function (item) {
          return item.bookingDate == self.currentDateFormat
        })
      }
      return self.booking_history
    },
    room_list_F: function () {
      const self = this
      return self.room_list.filter(function (item) {
        return item.type == 'F'
      })
    },
    room_list_M: function () {
      const self = this
      return self.room_list.filter(function (item) {
        return item.type == 'M'
      })
    },
    getField: function () {
      var fields = []
      if(this.filtersourceList.sourceList == 1 || this.filtersourceList.sourceList == 3 || this.filtersourceList.sourceList == 5){
        fields = [
          {
            label: this.$t('menu.form.member'),
            field: 'member',
            sortable: false,
            isKey: false,
            width: "31%",
          },
          {
            label: '備註',
            field: 'remark',
            sortable: false,
            isKey: false,
            width: "25%",
          },
          {
            label: '時間',
            field: 'booking_time',
            sortable: false,
            isKey: false,
            width: "10%",
          },
          {
            label: '理療師',
            field: 'therapist',
            sortable: false,
            isKey: false,
            width: "15%",
          },
          {
            label: '到達',
            field: 'arrival',
            sortable: false,
            isKey: false,
            width: "10%",
          },
          {
            label: '建立者',
            field: 'created_from',
            sortable: false,
            isKey: false,
            width: "13%",
          },
          {
            label: '',
            field: 'action',
            sortable: false,
            isKey: false,
            width: "10%",
          }
        ]
      }else if(this.filtersourceList.sourceList == 2){
        fields = [
          {
            label: this.$t('menu.form.member'),
            field: 'member',
            sortable: false,
            isKey: false,
            width: "26%",
          },
          {
            label: '備註',
            field: 'remark',
            sortable: false,
            isKey: false,
            width: "21%",
          },
          {
            label: '時間',
            field: 'booking_time',
            sortable: false,
            isKey: false,
            width: "7%",
          },
          {
            label: '理療師',
            field: 'therapist',
            sortable: false,
            isKey: false,
            width: "17%",
          },
          {
            label: '到達',
            field: 'arrival',
            sortable: false,
            isKey: false,
            width: "5%",
          },
          {
            label: '房',
            field: 'room',
            sortable: false,
            isKey: false,
            width: "7%",
          },
          {
            label: 'IN',
            field: 'checkin',
            sortable: false,
            isKey: false,
            width: "7%",
          },
          {
            label: 'OUT',
            field: 'checkout',
            sortable: false,
            isKey: false,
            width: "11%",
          },
          {
            label: '',
            field: 'action',
            sortable: false,
            isKey: false,
            width: "10%",
          }
        ]
      }else if(this.filtersourceList.sourceList == 4){
        fields = [
          {
            label: this.$t('menu.form.member'),
            field: 'member',
            sortable: false,
            isKey: false,
            width: "32%",
          },
          {
            label: '時間',
            field: 'booking_time',
            sortable: false,
            isKey: false,
            width: "10%",
          },
          {
            label: '理療師',
            field: 'therapist',
            sortable: false,
            isKey: false,
            width: "20%",
          },
          {
            label: '備註',
            field: 'remark',
            sortable: false,
            isKey: false,
            width: "23%",
          },
          {
            label: '取消原因',
            field: 'cancel_reason',
            sortable: false,
            isKey: false,
            width: "23%",
          },
          {
            label: '',
            field: 'action',
            sortable: false,
            isKey: false,
            width: "10%",
          }
        ]
      }
      return fields
    },
    timeout_list: function () {
      const self = this
      return self.timer_list.filter(function (item) {
        return item.timeout == 1
      })
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = 'Follow-Up Mode'
      }
    },
    allday() {
      const self = this
      if(self.allday == 1){
        self.eventInfo.bookingStartTime = {
          hours: '11',
          minutes: '00',
          seconds: '0'
        }
        self.eventInfo.bookingEndTime = {
          hours: '22',
          minutes: '30',
          seconds: '0'
        }
      }
    },
    allstaff() {
      const self = this
      self.eventInfo.org_therapist = []
      if(self.roster_list.length > 0 ){
        self.roster_list.forEach((item) => {
          if(item.id != "9999" && item.id != "99999"){
            self.eventInfo.org_therapist.push(item.id)
          }
        })
      }
    }
  },
  created() {
    const self = this
    var fd_offset = self.currentDate.getTimezoneOffset()
    var date = new Date(self.currentDate.getTime() - fd_offset * 60 * 1000)
    self.currentDateFormat = date.toISOString().split('T')[0]
    var date_day = new Date().getDay()
    var day_list = ['日', '一', '二', '三', '四', '五', '六']
    self.date_day = day_list[date_day]
    self.today_date = date.toISOString().split('T')[0]
    self.endDateFormat = self.currentDateFormat
    self.branch = this.$route.params.branch
    self.lang = localStorage.getItem('locale')
    setTimeout(function () {
      document.getElementById('confirmModal_open').click()
      setTimeout(function () {
        document.getElementById('staff_code').focus()
      }, 500)
    }, 300)
    setInterval(this.getNow, 1000)
    setInterval(this.check_timer, 10000)
    //self.getrealip()
  },
  mounted() {
    const self = this
    this.emitter.on('lang_changed', (lang) => {
      self.initialize()
      self.lang = lang
    })
    window.Echo.channel('booking_channel')
    .listen('.booking', (e) => {
      if(self.branch_id == e.message){
        self.need_update = true
      }
    })
    window.Echo.channel('machine_channel')
    .listen('.machine_timer', (e) => {
      if(self.branch_id == e.message){
        self.get_timer()
      }
    })
  },
  methods: {
    getrealip(){
      const self = this
      var branch_list = []
      this.axios
        .post('/booking/prepare', this.formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(function (response) {
          branch_list = response.data.branch_list
          self.axios
          .get('https://record.ankh.com.hk/api/getip', {})
          .then(function (res) {
            const ip = res.data.ip
            branch_list.forEach((item) => {
              if(item.ip_address == ip || item.ip_address2 == ip){
                self.allowed = true
              }
            })
          })
          .catch((error) => { 
            console.error(error)
            self.allowed = true
          })
        })
    },
    getAvailableTherapistList() {
      const self = this
      if(self.today_date == self.currentDateFormat){
        var list = self.dataSource
        self.AvailableTherapistList = self.roster_list
        self.AvailableTherapistList.forEach((item) => {
          item.available = true
          if(item.id == '9999' || item.id == '99999'){
            item.available = false
          }
        })
        list = list.filter(function (item) {
          return item.status > 0 && (self.currentTime >= item.start_time && item.end_time > self.currentTime) || (self.currentTime < item.start_time && self.currentTime1 > item.start_time)
        })
        list.forEach((item) => {
          if(item.status == 1){
            if(Array.isArray(item.therapist) == false){
              item.therapist = item.therapist.toString().split(",")
            }
            item.therapist.forEach((therapist) => {
              var index = self.AvailableTherapistList.findIndex(el => el.id == therapist)
              if(index >= 0){
                self.AvailableTherapistList[index].available = false
              }
            })
          }
        })
      }else{
        self.AvailableTherapistList = []
        this.formData = new FormData()
        this.formData.append('staff_id', self.staff)
        this.formData.append('start_booking_date', self.today_date)
        this.formData.append('end_booking_date', self.today_date)
        this.formData.append('branch', self.branch)
        this.formData.append('history', '1')
        this.formData.append('orderBy', self.orderBy)
        this.formData.append('orderByFM', self.orderByFM)
        this.axios
        .post('/booking/master', this.formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(function (response) {
          if(response.data.roster_list.length > 0 ){
            response.data.roster_list.forEach((item) => {
              if(self.staff == 'all' || item.id == self.staff_id){
                item.text = item.nickname
                item.name = item.nickname
                item.color = '#ededed'
                self.AvailableTherapistList.push(item)
              }
            })
          }
          var list = response.data.data
          if(list.length > 0 ){
            list.forEach((item) => {
              item.bookingDate = item.booking_date
              item.startDate = item.bookingDate+'T'+item.start_time+':00'
              item.endDate = item.bookingDate+'T'+item.end_time+':00'
              item.bookingStartTime = {
                hours: item.start_time.substring(0, 2),
                minutes: item.start_time.substring(3, 5),
                seconds: '00'
              }
              item.bookingEndTime = {
                hours: item.end_time.substring(0, 2),
                minutes: item.end_time.substring(3, 5),
                seconds: '00'
              }
            })
          }
          self.AvailableTherapistList.forEach((item) => {
            item.available = true
            if(item.id == '9999' || item.id == '99999'){
              item.available = false
            }
          })
          list = list.filter(function (item) {
            return item.status > 0 && (self.currentTime >= item.start_time && item.end_time > self.currentTime) || (self.currentTime < item.start_time && self.currentTime1 > item.start_time)
          })
          list.forEach((item) => {
            if(item.status == 1){
              if(Array.isArray(item.therapist) == false){
                item.therapist = item.therapist.toString().split(",")
              }
              item.therapist.forEach((therapist) => {
                var index = self.AvailableTherapistList.findIndex(el => el.id == therapist)
                if(index >= 0){
                  self.AvailableTherapistList[index].available = false
                }
              })
            }
          })
        })
      }
    },
    getAvailableTherapistList2() {
      const self = this
      self.AvailableTherapistList2 = self.therapist_list
      self.AvailableTherapistList2.forEach((item) => {
        item.available = true
        if(item.id == '9999' || item.id == '99999'){
          item.available = false
        }
      })
      var list = self.bookingDatedataSource.filter(function (item) {
        return item.status > 0 && (self.eventInfo.start_time >= item.start_time && item.end_time > self.eventInfo.start_time) || (self.eventInfo.start_time < item.start_time && self.eventInfo.end_time > item.start_time)
      })
      list.forEach((item) => {
        if(item.status == 1){
          if(Array.isArray(item.therapist) == false){
            item.therapist = item.therapist.toString().split(",")
          }
          item.therapist.forEach((therapist) => {
            var index = self.AvailableTherapistList2.findIndex(el => el.id == therapist)
            if(index >= 0){
              self.AvailableTherapistList2[index].available = false
            }
          })
        }
      })
    },
    check_timer: function() {
      const self = this
      var timeout = 0
      var warning = false
      var danger = false
      if(self.timer_list.length > 0){
        self.timer_list.forEach((timer) => {
          timer.timeout = 0
          const timeNow = new Date().getTime()
          const timeEnd = new Date(timer.countdown_date+ " " +timer.estimate_end_time).getTime()
          const timeDifference = timeEnd - timeNow
          const millisecondsInOneSecond = 1000
          const millisecondsInOneMinute = millisecondsInOneSecond * 60
          const remainderDifferenceInMinutes = timeDifference / millisecondsInOneMinute
          const remainderDifferenceInSeconds = (timeDifference % millisecondsInOneMinute) / millisecondsInOneSecond
          const remainingMinutes = Math.floor(remainderDifferenceInMinutes)
          const remainingSeconds = Math.floor(remainderDifferenceInSeconds)
          var pec = 0
          var second = parseInt(timer.minutes) * 60
          var remain = Math.floor(timeDifference / millisecondsInOneSecond)
          if(second-remain > 0){
            pec = Math.floor(((second-remain) / second) * 100)
            if(pec > 75){
              danger = true
            }else if(pec > 50){
              warning = true
            }
          }
          if(timeDifference > 0){
            timer.remain = remainingSeconds + "秒"
            if(remainingMinutes > 0){
              timer.remain = remainingMinutes + "分鐘" + remainingSeconds + "秒"
            }
          }else{
            timeout = 1
            timer.remain = "已超時!"
            timer.timeout = 1
            if(self.timerdialogcount == 0 && self.timer_click == false && self.edit_mode == false){
              self.timer_click = true
              self.timer_list_existed = true
              document.getElementById('timerModal_open').click()
            }
          }
        })
        setTimeout(function () {
          if(danger == true){
          self.timerfontcolor = 'danger'
          }else if(warning == true){
            self.timerfontcolor = 'warning'
          }else{
            self.timerfontcolor = 'success'
          }
        }, 1000)
      }
      if(timeout == 0 && self.timer_list_existed == true){
        self.timerfontcolor = 'success'
        document.getElementById('timerModal_close').click()
        self.timer_list_existed = false
        self.close_timer()
      }
    },
    getNow: function() {
      const self = this
      const today = new Date();
      const after1hour = new Date();
      after1hour.setHours(after1hour.getHours() + 1)
      const time = today.getHours().toString().padStart(2, '0') + ":" + today.getMinutes().toString().padStart(2, '0')
      const time2 = after1hour.getHours().toString().padStart(2, '0') + ":" + after1hour.getMinutes().toString().padStart(2, '0')
      self.currentTime = time
      self.currentTime1 = time2
      if(self.timerdialogcount > 0){
        self.timerdialogcount--
      }
    },
    close_timer(){
      const self = this
      self.timerdialogcount = 60
      self.timer_click = false
    },
    arrival_now(){
      const self = this
      self.eventInfo.arrivalTime = {
        hours: self.currentTime.substring(0, 2),
        minutes: self.currentTime.substring(3, 5),
        seconds: '00'
      }
    },
    getTimeDiff(time, diff){
      const formatTime = time.split(":")[0]*60 + time.split(":")[1]*1
      const current = this.currentTime.split(":")[0]*60 + this.currentTime.split(":")[1]*1
      if(current-formatTime > diff){
        return true
      }
      return false
    },
    therapist_check(){
      const self = this
      var dataSource = []
      var therapist_nameList = []
      self.booking_idList  = []
      self.update_sync_delete = '0'
      self.therapist_booked = ''
      setTimeout(function () {
        if(self.eventInfo.org_therapist.length > 0 && self.eventInfo.start_time){
          self.eventInfo.org_therapist.forEach((therapist) => {
            if(therapist != "9999" && therapist != "99999"){
              dataSource = self.bookingDatedataSource.filter(function (item) {
                return item.id != self.eventInfo.id && item.therapist == therapist && item.status == 1
              })
              dataSource.forEach((booking) => {
                if((self.eventInfo.start_time >= booking.start_time && self.eventInfo.end_time <= booking.end_time) || (self.eventInfo.end_time > booking.end_time && self.eventInfo.start_time < booking.end_time) || (booking.start_time > self.eventInfo.start_time && self.eventInfo.end_time > booking.start_time)){
                  self.booking_idList.push(booking.id)
                  therapist_nameList.push(therapist)
                }
              })
            }
          })
          if(therapist_nameList.length > 0){
            self.therapist_booked = self.getlistitem('therapist', therapist_nameList)
          }
        }
      }, 500)
    },
    room_check() {
      const self = this
      self.full_room = ''
      self.room_list = JSON.parse(JSON.stringify(self.org_room_list))
      var check_dup = self.bookingDatedataSource.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.id === value.id
        ))
      )
      var dataSource = check_dup.filter(function (item) {
        return item.id != self.eventInfo.id
      })
      dataSource.forEach((item) => {
        if((self.eventInfo.start_time >= item.start_time && item.end_time > self.eventInfo.start_time) || (self.eventInfo.start_time < item.start_time && self.eventInfo.end_time > item.start_time)){
          if(item.room){
            var index = self.room_list.findIndex(el => el.id === item.room)
            if(index >= 0){
              var position = self.room_list[self.room_list.findIndex(el => el.id === item.room)].room.search("已使用")
              if(position == -1){
                self.room_list[self.room_list.findIndex(el => el.id === item.room)].room = self.room_list[self.room_list.findIndex(el => el.id === item.room)].room + "-已使用"
              }
            }
          }
        }
      })
      setTimeout(function () {
        if(self.eventInfo.gender){
          if(self.eventInfo.gender == 'M'){
            var used_room_M = dataSource.filter(function (item) {
              return item.special_event == 0 && item.status > 0 && ((self.eventInfo.start_time >= item.start_time && item.end_time > self.eventInfo.start_time) || (self.eventInfo.start_time < item.start_time && self.eventInfo.end_time > item.start_time)) && item.gender == 'M' && item.therapist != '9999' && item.therapist != '99999'
            })
            if(used_room_M.length >= self.room_list_M.length){
              self.full_room = '男賓房間數量不足'
            }
          }else if(self.eventInfo.gender == 'F'){
            var used_room_F = dataSource.filter(function (item) {
              return item.special_event == 0 && item.status > 0 && ((self.eventInfo.start_time >= item.start_time && item.end_time > self.eventInfo.start_time) || (self.eventInfo.start_time < item.start_time && self.eventInfo.end_time > item.start_time)) && item.gender == 'F' && item.therapist != '9999' && item.therapist != '99999'
            })
            if(used_room_F.length >= self.room_list_F.length){
              self.full_room = '女賓房間數量不足'
            }
          }
        }
      }, 500)
    },
    getBookingHistory(data){
      const self = this
      if(data.history.length == 0){
        this.formData = new FormData()
        this.formData.append('id', data.id)
        this.axios
        .post('/booking/historyDetail', this.formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(function (response) {
          data.history = response.data.data
          if(data.history.length > 0){
            data.history.forEach((history) => {
              var HDate = new Date(history.created_at)
              history.created_at = HDate.getFullYear() + '-' +('0' + (HDate.getMonth()+1)).slice(-2)+ '-' +  ('0' + HDate.getDate()).slice(-2) + ' '+('0' + (HDate.getHours())).slice(-2)+ ':'+('0' + (HDate.getMinutes())).slice(-2)+ ':'+('0' + (HDate.getSeconds())).slice(-2)
            })
          }
        }).catch((error) => { 
          console.error(error)
          self.show = false
        })
      }
      self.get_history(data)
    },
    get_history(item) {
      const self = this
      self.selected_history= []
      self.selected_history.push(item)
      document.getElementById('bookinghistorybutton').click()
    },
    get_dataSourceList(type) {
      const self = this
      let list = []
      if(type == 1){
        list = this.dataSource.filter(function (item) {
          return item.status == 1 && item.member_no && !item.arrival_time
        })
      }else if(type == 2){
        list = this.dataSource.filter(function (item) {
          return item.status == 1 && item.member_no && item.arrival_time
        })
      }else if(type == 3){
        list = this.dataSource.filter(function (item) {
          return item.status == 1 && item.member_no && item.therapist == 99999
        })
      }else if(type == 4){
        list = this.dataSource.filter(function (item) {
          return item.status == -1
        })
      }else if(type == 5){
        list = this.dataSource.filter(function (item) {
          return item.status == 1 && item.member_no && item.therapist == 9999
        })
      }else{
        list = this.dataSource.filter(function (item) {
          return item.status == 1 && item.member_no
        })
      }
      if(this.filtersourceList.therapist){
        list = list.filter(function (item) {
          return item.therapist == self.filtersourceList.therapist
        })
      }
      if(this.filtersourceList.special != 'all'){
        if(this.filtersourceList.special == 'new_join'){
          list = list.filter(function (item) {
            return item.new_join == 1
          })
        }else if(this.filtersourceList.special == 'specify'){
          list = list.filter(function (item) {
            return item.specify == 1
          })
        }else if(this.filtersourceList.special == 'recommend'){
          list = list.filter(function (item) {
            return item.recommend == 1
          })
        }
      }
      if(this.filtersourceList.freetext){
        list = list.filter(function (item) {
          return item?.remark?.includes(self.filtersourceList.freetext) || item?.member_no?.includes(self.filtersourceList.freetext)
        })
      }
      return list
    },
    back_history() {
      const self = this
      self.selected_history = []
      document.getElementById('searchbookingbutton').click()
    },
    back_search() {
      const self = this
      self.member_no = ''
    },
    booking_click(item) {
      const self = this
      self.edit_mode = true
      self.therapist_booked = ''
      self.dragmode = false
      self.eventAction = "edit"
      self.eventInfo = item
      self.old_eventInfo = {
        id: self.eventInfo.id,
        member_no: self.eventInfo.member_no,
        name: self.eventInfo.name,
        nickname: self.eventInfo.nickname,
        gender: self.eventInfo.gender,
        therapist: self.eventInfo.therapist,
        org_therapist: self.eventInfo.org_therapist,
        without_therapist: self.eventInfo.without_therapist,
        without_advisor: self.eventInfo.without_advisor,
        advisor: self.eventInfo.advisor,
        nutritionist: self.eventInfo.nutritionist,
        sports_science_consultant: self.eventInfo.sports_science_consultant,
        org_sports_science_consultant: self.eventInfo.org_sports_science_consultant,
        sports_science_type: self.eventInfo.sports_science_type,
        chinese_medicine: self.eventInfo.chinese_medicine,
        bookingDate: self.eventInfo.bookingDate,
        bookingStartTime: self.eventInfo.bookingStartTime,
        bookingEndTime: self.eventInfo.bookingEndTime,
        arrivalTime: self.eventInfo.arrivalTime,
        arrival_time: self.eventInfo.arrival_time,
        startDate: self.eventInfo.startDate,
        endDate: self.eventInfo.endDate,
        start_time: self.eventInfo.start_time,
        end_time: self.eventInfo.end_time,
        room: self.eventInfo.room,
        machine: self.eventInfo.machine,
        new_join: self.eventInfo.new_join,
        specify: self.eventInfo.specify,
        recommend: self.eventInfo.recommend,
        reconfirm: self.eventInfo.reconfirm,
        remark: self.eventInfo.remark,
        remark_call: self.eventInfo.remark_call,
        remark_internal: self.eventInfo.remark_internal,
        member_type: self.eventInfo.member_type,
        booking_show: self.eventInfo.booking_show,
        deal: self.eventInfo.deal,
        cancel_reason: self.eventInfo.cancel_reason,
        cancel_reason_remark: self.eventInfo.cancel_reason_remark,
        special_event: self.eventInfo.special_event,
        checkin_date: self.eventInfo.checkin_date,
        checkin_time: self.eventInfo.checkin_time,
        checkout_date: self.eventInfo.checkout_date,
        checkout_time: self.eventInfo.checkout_time,
        created_from: self.eventInfo.created_from,
        created_by: self.eventInfo.created_by,
        status: self.eventInfo.status,
        block_list: self.eventInfo.block_list,
        block_list_remark: self.eventInfo.block_list_remark,
        block_list_color: self.eventInfo.block_list_color,
        block_list_color_desc: self.eventInfo.block_list_color_desc,
      }
      setTimeout(function () {
        document.getElementById('eventModal_open').click()
        self.room_check()
      }, 300)
      if(self.eventInfo.member_no){
        self.member_check(self.eventInfo)
      }
    },
    showPopup() {
      const self = this
      self.action_by = ''
      self.edit_mode = true
      self.allday = 0
      self.allstaff = 0
      self.dragmode = false
      self.therapist_booked = ''
      self.full_room = ''
      self.therapist_list = self.roster_list
      self.eventInfo = {
        id: 0,
        member_no: '',
        name: '',
        gender: '',
        nickname: '',
        therapist: 0,
        org_therapist: [],
        without_therapist: 0,
        without_advisor: 0,
        advisor: [],
        nutritionist: [],
        sports_science_consultant: 0,
        org_sports_science_consultant: [],
        sports_science_type: '',
        chinese_medicine: [],
        bookingDate: self.currentDateFormat,
        bookingStartTime: {
          hours: '11',
          minutes: '00',
          seconds: '0'
        },
        bookingEndTime: {
          hours: '11',
          minutes: '30',
          seconds: '0'
        },
        arrivalTime: {
          hours: '00',
          minutes: '00',
          seconds: '0'
        },
        arrival_time: '',
        startDate: '',
        endDate: '',
        start_time: '11:00',
        end_time: '11:30',
        room: '',
        machine: [],
        new_join: '0',
        specify: '0',
        recommend: '0',
        reconfirm: 'None',
        remark: '',
        remark_call: '',
        remark_internal: '',
        member_type: '',
        booking_show: 0,
        deal: 0,
        cancel_reason: '',
        cancel_reason_remark: '',
        special_event: 0,
        checkin_date: '',
        checkin_time: '',
        checkout_date: '',
        checkout_time: null,
        created_from: 1,
        created_by: '',
        status: 1,
        block_list: false,
        block_list_remark: '',
        block_list_color: '',
        block_list_color_desc: '',
      }
      self.eventAction = "create"
      self.room_check()
      document.getElementById('eventModal_open').click()
    },
    back() {
      const self = this
      self.edit_mode = false
      var index = self.dataSource.findIndex(el => el.id === self.eventInfo.id && el.therapist === self.eventInfo.therapist)
      if(index >= 0){
        self.old_eventInfo.bookingStartTime = {
          hours: self.old_eventInfo.start_time.substring(0, 2),
          minutes: self.old_eventInfo.start_time.substring(3, 5),
          seconds: '00'
        }
        self.old_eventInfo.bookingEndTime = {
          hours: self.old_eventInfo.end_time.substring(0, 2),
          minutes: self.old_eventInfo.end_time.substring(3, 5),
          seconds: '00'
        }
        self.dataSource[self.dataSource.findIndex(el => el.id === self.eventInfo.id && el.therapist === self.eventInfo.therapist)] = self.old_eventInfo
      }
      if(self.dataSource.length > 0){
        if(self.dataSource[self.dataSource.length-1].id == 0){
          self.dataSource.pop()
        }
      }
      self.room_list = JSON.parse(JSON.stringify(self.org_room_list))
      document.getElementById('eventModal_close').click()
      if(self.action_type == 'submit_continue'){
        self.get_api()
      }
      self.action_type = ''
    },
    submit() {
      const self = this
      if (self.eventInfo.bookingDate.length != 10) {
        var fd_offset = self.eventInfo.bookingDate.getTimezoneOffset()
        var date = new Date(self.eventInfo.bookingDate.getTime() - fd_offset * 60 * 1000)
        self.eventInfo.bookingDate = date.toISOString().split('T')[0]
      }
      self.eventInfo.startDate = self.eventInfo.bookingDate+"T"+self.eventInfo.bookingStartTime.hours.toString().padStart(2, '0')+":"+self.eventInfo.bookingStartTime.minutes.toString().padStart(2, '0')+":00"
      self.eventInfo.endDate = self.eventInfo.bookingDate+"T"+self.eventInfo.bookingEndTime.hours.toString().padStart(2, '0')+":"+self.eventInfo.bookingEndTime.minutes.toString().padStart(2, '0')+":00"
      self.eventInfo.arrival_time = self.eventInfo.arrivalTime.hours.toString().padStart(2, '0')+":"+self.eventInfo.arrivalTime.minutes.toString().padStart(2, '0')
      self.eventInfo.status = 1
      self.update_api()
      document.getElementById('eventModal_close').click()
    },
    cancel_booking() {
      const self = this
      self.back()
      self.modalShow = true
    }, 
    confirm_delete() {
      const self = this
      self.eventInfo.status = -1
      self.update_api()
      self.modalShow = false
      document.getElementById('eventModal_close').click()
    },
    cancel_delete() {
      const self = this
      self.modalShow = false
    },
    initialize() {
      const self = this
      self.special_event_list = [
        {
          id: 1,
          event: '午膳'
        },
        {
          id: 2,
          event: '休假'
        },
        {
          id: 3,
          event: '培訓'
        },
        {
          id: 4,
          event: '會議'
        },
        {
          id: 5,
          event: '其他'
        }
      ]
      this.formData = new FormData()
      this.formData.append('branch', self.branch)
      this.axios
      .post('/booking/prepare', this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        self.staff_list = response.data.staff_list
        self.org_room_list = JSON.parse(JSON.stringify(response.data.room_list))
        self.room_list = JSON.parse(JSON.stringify(response.data.room_list))
        self.machine_list = JSON.parse(JSON.stringify(response.data.machine_list))
        self.machine_sku_list = JSON.parse(JSON.stringify(response.data.machine_sku_list))
        self.branch_list = response.data.branch_list
        self.cancel_reason_list = response.data.cancel_reason_list
        self.member_type_list = response.data.member_type_list
        var search_branch = response.data.branch_list.filter(function (item) {
          return item.branch == self.branch
        })
        if(search_branch){
          self.branch_id = search_branch[0].id
        }
        var user_role_list = response.data.user_role_list
        self.staff_list.forEach((item) => {
          item.text = item.nickname
          if(item.active == 1){
            var therapist = user_role_list.filter(function (role) {
              return item.id == role.parent_id && role.role == 4 && role.status == 1
            })
            if(therapist.length > 0){
              self.full_therapist_list.push(item)
            }
            if(item.shop.includes(self.branch_id) && user_role_list.length > 0){
              var advisor = user_role_list.filter(function (role) {
                return item.id == role.parent_id && role.role == 3 && role.status == 1
              })
              if(advisor.length > 0){
                self.advisor_list.push(item)
              }
              var chinese_medicine = user_role_list.filter(function (role) {
                return item.id == role.parent_id && role.role == 7 && role.status == 1
              })
              if(chinese_medicine.length > 0){
                self.chinese_medicine_list.push(item)
              }
              var nutritionist = user_role_list.filter(function (role) {
                return item.id == role.parent_id && role.role == 12 && role.status == 1
              })
              if(nutritionist.length > 0){
                self.nutritionist_list.push(item)
              }
              var sports_science_consultant = user_role_list.filter(function (role) {
                return item.id == role.parent_id && role.role == 34 && role.status == 1
              })
              if(sports_science_consultant.length > 0){
                self.sports_science_consultant_list.push(item)
              }
            }
          }
        })
        setTimeout(function () {
          self.get_api()
        }, 500)
      })
    },
    get_api() {
      const self = this
      self.show = true
      self.dataSource = []
      self.roster_list = [
        {
          name: '舊客Waiting List',
          nickname: '舊客Waiting List',
          text: '舊客Waiting List',
          id: 9999,
          color: '#ededed',
        },
        {
          name: '新客Waiting List',
          nickname: '新客Waiting List',
          text: '新客Waiting List',
          id: 99999,
          color: '#ededed',
        }
      ]
      this.formData = new FormData()
      this.formData.append('staff_id', self.staff)
      this.formData.append('start_booking_date', self.currentDateFormat)
      this.formData.append('end_booking_date', self.endDateFormat)
      this.formData.append('branch', self.branch)
      this.formData.append('history', '1')
      this.formData.append('orderBy', self.orderBy)
      this.formData.append('orderByFM', self.orderByFM)
      this.axios
      .post('/booking/master', this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        self.need_update = false
        if(self.version){
          if(self.version != response.data.version){
            self.version_update = true
            setTimeout(function () {
              self.reload()
            }, 5000)
          }
        }else{
          self.version_update = false
          self.version = response.data.version
        }
        if(response.data.roster_list.length > 0 ){
          response.data.roster_list.forEach((item) => {
            if(self.staff == 'all' || item.id == self.staff_id){
              item.text = item.nickname
              item.name = item.nickname
              item.color = '#ededed'
              self.roster_list.push(item)
            }
          })
          self.therapist_list = self.roster_list
        }
        self.dataSource = response.data.data
        self.bookingDatedataSource = response.data.data
        if(self.dataSource.length > 0 ){
          self.dataSource.forEach((item, idx, array) => {
            if(item.name == '找不到相關會員編號' && item.created_from != '1'){
              self.member_check(item)
            }
            if(item.member_no && !item.name){
              //self.member_check(item)
            }
            item.bookingDate = item.booking_date
            item.startDate = item.bookingDate+'T'+item.start_time+':00'
            item.endDate = item.bookingDate+'T'+item.end_time+':00'
            item.bookingStartTime = {
              hours: item.start_time.substring(0, 2),
              minutes: item.start_time.substring(3, 5),
              seconds: '00'
            }
            item.bookingEndTime = {
              hours: item.end_time.substring(0, 2),
              minutes: item.end_time.substring(3, 5),
              seconds: '00'
            }
            if(item.arrival_time){
              item.arrivalTime = {
                hours: item.arrival_time.substring(0, 2),
                minutes: item.arrival_time.substring(3, 5),
                seconds: '00'
              }
            }else{
              item.arrivalTime = {
                hours: '00',
                minutes: '00',
                seconds: '0'
              }
            }

            // Advisor array
            var advisor = []
            if(item.advisor){
              var advisor_re = item.advisor
              advisor_re = advisor_re.replace('[', '')
              advisor_re = advisor_re.replace(']', '')
              advisor_re = advisor_re.replace('"', '')
              advisor = advisor_re.split(',')
            }
            item.advisor = advisor

            // Chinese Medicine array
            var chinese_medicine = []
            if(item.chinese_medicine){
              var chinese_medicine_re = item.chinese_medicine
              chinese_medicine_re = chinese_medicine_re.replace('[', '')
              chinese_medicine_re = chinese_medicine_re.replace(']', '')
              chinese_medicine_re = chinese_medicine_re.replace('"', '')
              chinese_medicine = chinese_medicine_re.split(',')
            }
            item.chinese_medicine = chinese_medicine

            // Nutritionist array
            var nutritionist = []
            if(item.nutritionist){
              var nutritionist_re = item.nutritionist
              nutritionist_re = nutritionist_re.replace('[', '')
              nutritionist_re = nutritionist_re.replace(']', '')
              nutritionist_re = nutritionist_re.replace('"', '')
              nutritionist = nutritionist_re.split(',')
            }
            item.nutritionist = nutritionist

            // Sports Science Consultant array
            /*
            var sports_science_consultant = []
            if(item.sports_science_consultant){
              var sports_science_consultant_re = item.sports_science_consultant
              sports_science_consultant_re = sports_science_consultant_re.replace('[', '')
              sports_science_consultant_re = sports_science_consultant_re.replace(']', '')
              sports_science_consultant_re = sports_science_consultant_re.replace('"', '')
              sports_science_consultant = sports_science_consultant_re.split(',')
            }
            item.sports_science_consultant = sports_science_consultant
            */

            // Machine array
            var machine = []
            if(item.machine){
              var machine_re = item.machine
              machine_re = machine_re.replace('[', '')
              machine_re = machine_re.replace(']', '')
              machine_re = machine_re.replace('"', '')
              machine = machine_re.split(',')
            }
            item.machine = machine

            item.without_therapist = item.without_therapist.toString()
            item.without_advisor = item.without_advisor.toString()

            if (idx === array.length - 1){
              setTimeout(function () {
                self.get_timer()
                self.show = false
              }, 300)
            }
          })
        }else{
          setTimeout(function () {
            self.show = false
          }, 300)
        }
      })
    },
    get_timer(){
      const self = this
      this.formData = new FormData()
      this.formData.append('branch', self.branch)
      this.axios
      .post('https://center.ankh.com.hk/api/timer/list', this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI3IiwianRpIjoiNTdlMmQ2MjFmYWFlM2Q3MWI4MDg4NWIyZjk3ODNjOTY1YjA4ZDRkMWM5ZDJjNjg2MDVmNjAxYmFjNjRhMjY3YzExYWY0Zjg5NTU3ZDg0ZjIiLCJpYXQiOjE2ODY1MzUwNDAuMzA5MjE3LCJuYmYiOjE2ODY1MzUwNDAuMzA5MjIzLCJleHAiOjE4NDQzODc4NDAuMjk2ODI0LCJzdWIiOiIzMTYiLCJzY29wZXMiOltdfQ.lLKJzzAYQl01mg7VuTsPrsJg1iXlKCpzWRR5xhp4etsuKK80NJga1LUZCjWDdCc48BlcQY_Iqh1bOHRMb9pCFgaSQeopSBdcEon3s7U4294Znkt620ujU9OmAr9dhEXRRkblFdwqsR4XjC0pWtX9ILOltvyW9Xmnssy3XbxCQah95kvL4sAF75ro3C7IsonqjxgE0HrkxlpHxgWgFaiktzed9GyUxyToBqWEv9nvmtJLJquebko1NRIKuwZplBnnycnk-HM_IN_HsmaBsa8vmDn3B7bvITdTBTH6xDV7HOrB38ioBpjNDPZggHDauES6esgA0QqBOZfGY3e8JWiWD08cywJVPU97YnUvP13l7sSom66u5krBQCnH9dNrPg2YjdWoPKoY_m8pO2TjcI92ETXlw6zTaFme_wbeluO487qFBKkrfcquUd2DU0IALWM6hzdyn67tYZvts7DN-EOe9G7jUZ0tgGfhBRCFAIfHZ2g22FN8-hQv_TobYG8Q9VxJ6jX0faP-xRkN3B6Y2gEqym80a9YaXyS3jRlw0HCV05LWsmi_zXButpF7ygszj78ZyEcC8cXa3GAgQIRx2pnoo2Hi6vmxRwfpjS2ro3W3iKvBG4KpBKuhbqFe64KLoH_xZeLhx4GIjJhQFSHhbJlMKf_Q6l3vw322hi0GjHU42SE',
        },
      })
      .then(function (response) {
        self.timer_list = response.data.timer_list.filter(function (item) {
          return item.start_time && item.end_time == null
        })
        self.check_timer()
      })
    },
    update_api() {
      const self = this
      self.edit_mode = false
      if(self.update_sync_delete == '1'){
        self.eventInfo.booking_idList = self.booking_idList
      }
      self.show = true
      this.axios
        .post('/booking/update', {
          data: self.eventInfo,
          branch: self.branch,
          staff_id: self.staff_id,
          action_by: self.action_by,
        })
        .then(function () {
          if(self.action_type != 'submit_continue'){
            self.action_by = ''
            self.eventAction = "edit"
            self.room_list = JSON.parse(JSON.stringify(self.org_room_list))
            self.get_api()
          }else{
            self.toasts.push({
              cus_class: 'toast-success',
              data: self.eventInfo,
              content: '預約成功!請選擇下一個預約日子'
            })
            self.show = false
            self.eventInfo.id = 0
            self.eventInfo.therapist = 0
            self.eventInfo.org_therapist = []
            document.getElementById('eventModal_open').click()
          }
        })
    },
    action_request(){
      document.getElementById('requestModal_open').click()
    },
    update_request(){
      const self = this
      self.show = true
      this.axios
      .post('/booking/request', {
        data: self.eventInfo,
        action_by: self.action_by,
        request_text: self.request_text,
      })
      .then(function () {
        self.show = false
      })
      .catch((error) => { 
        console.error(error)
        self.show = false
      })
    },
    member_check(info) {
      const self = this
      if (info.member_no) {
        self.vaild_member = true
        //POS API
        this.axios({
        method: 'post',
        url: 'https://record.ankh.com.hk/api/pos/v1/fetch/member',
        data: {
          find: info.member_no
        },
        headers: {
          'Authorization': `Bearer 68|003iXlulhk5hFHGUHVMyEenUbGxCXPAVDVNJIw38`,
          'X-Requested-With': `XMLHttpRequest`
        },
        withCredentials: true,
        }).then(function (response) {
          if(response.data.result == "success"){
            info.member_no = response.data.member.memberId
            info.name = response.data.member.cardName
            if(!info.gender){
              info.gender = response.data.member.gender
            }
          }else{
            self.vaild_member = false
            info.name = "找不到相關會員編號"
          }
        })
      }
    },
    getlistitem(list, id) {
      const self = this
      var nickname = ""
      if(list == "special_event"){
        var selected = self.special_event_list.find(i => i.id == id)
        if(selected){
          return selected.event
        }
      }else if(list == "room"){
        selected = self.room_list.find(i => i.id == id)
        if(selected){
          return selected.room
        }
      }else if(list == "machine"){
        if(id){
          if(Array.isArray(id) == false){
            id = id.split(",")
          }
          id.forEach((machine, index) => {
            selected = self.machine_list.find(i => i.id == machine)
            if(selected){
              if(index == 0){
                nickname = selected.machine
              }else{
                nickname += ', '+selected.machine
              }
            }
          })
          return nickname
        }
      }else if(list == "machine_sku"){
        selected = self.machine_sku_list.find(i => i.id == id)
        if(selected){
          return selected.sku
        }
      }else if(list == "therapist"){
        if(id){
          if(Array.isArray(id) == false){
            id = id.toString().split(",")
          }
          id.forEach((therapist, index) => {
            selected = self.full_therapist_list.find(i => i.id == therapist)
            if(selected){
              if(index == 0){
                nickname = selected.nickname
              }else{
                nickname += ', '+selected.nickname
              }
            }
          })
          return nickname
        }
      }else if(list == "advisor"){
        if(id){
          if(Array.isArray(id) == false){
            id = id.split(",")
          }
          id.forEach((advisor, index) => {
            selected = self.advisor_list.find(i => i.id == advisor)
            if(selected){
              if(index == 0){
                nickname = selected.nickname
              }else{
                nickname += ', '+selected.nickname
              }
            }
          })
          return nickname
        }
      }else if(list == "nutritionist"){
        if(id){
          if(Array.isArray(id) == false){
            id = id.split(",")
          }
          id.forEach((nutritionist, index) => {
            selected = self.nutritionist_list.find(i => i.id == nutritionist)
            if(selected){
              if(index == 0){
                nickname = selected.nickname
              }else{
                nickname += ', '+selected.nickname
              }
            }
          })
          return nickname
        }
      }else if(list == "sports_science_consultant"){
        if(id){
          if(Array.isArray(id) == false){
            id = id.split(",")
          }
          id.forEach((sports_science_consultant, index) => {
            selected = self.sports_science_consultant_list.find(i => i.id == sports_science_consultant)
            if(selected){
              if(index == 0){
                nickname = selected.nickname
              }else{
                nickname += ', '+selected.nickname
              }
            }
          })
          return nickname
        }
      }
      else if(list == "chinese_medicine"){
        if(id){
          if(Array.isArray(id) == false){
            id = id.split(",")
          }
          id.forEach((chinese_medicine, index) => {
            selected = self.chinese_medicine_list.find(i => i.id == chinese_medicine)
            if(selected){
              if(index == 0){
                nickname = selected.nickname
              }else{
                nickname += ', '+selected.nickname
              }
            }
          })
          return nickname
        }
      }else if(list == "cancel_reason"){
        selected = self.cancel_reason_list.find(i => i.id == id)
        if(selected){
          return selected.name_tc
        }
      }else if(list == "staff"){
        selected = self.staff_list.find(i => i.id == id)
        if(selected){
          return selected.nickname
        }
      }else if(list == "member_type"){
        selected = self.member_type_list.find(i => i.id == id)
        if(selected){
          return selected.name_tc
        }
      }
    },
    change_day() {
      const self = this
      var fd_offset = this.currentDateFormat.getTimezoneOffset()
      var date = new Date(this.currentDateFormat.getTime() - fd_offset * 60 * 1000)
      var date_day = date.getDay()
      var day_list = ['日', '一', '二', '三', '四', '五', '六']
      self.date_day = day_list[date_day]
      self.currentDateFormat = date.toISOString().split('T')[0]
      self.endDateFormat = self.currentDateFormat
      self.get_api()
      if(self.eventAction == "create"){
        self.eventInfo.bookingDate = self.currentDateFormat
      }
    },
    therapistOption(type) {
      const self = this
      if(type == 'new_join'){
        self.eventInfo.specify = '0'
        self.eventInfo.recommend = '0'
      }else if(type == 'specify'){
        self.eventInfo.new_join = '0'
        self.eventInfo.recommend = '0'
      }else if(type == 'recommend'){
        self.eventInfo.new_join = '0'
        self.eventInfo.specify = '0'
      }
    },
    checkin() {
      const self = this
      var today = new Date()
      var fd_offset = today.getTimezoneOffset()
      var date = new Date(today.getTime() - fd_offset * 60 * 1000)
      self.eventInfo.checkin_date = date.toISOString().split('T')[0]
      self.eventInfo.checkin_time = date.toISOString().split('T')[1].substring(0, 8)
      self.submit()
    },
    checkout() {
      const self = this
      var today = new Date()
      var fd_offset = today.getTimezoneOffset()
      var date = new Date(today.getTime() - fd_offset * 60 * 1000)
      self.eventInfo.checkout_date = date.toISOString().split('T')[0]
      self.eventInfo.checkout_time = date.toISOString().split('T')[1].substring(0, 8)
      self.submit()
    },
    action_auth(type) {
      const self = this
      //document.getElementById('confirmModal_close').style = 'inline'
      self.action_type = type
      if((type == 'submit_continue' || type == 'submit') && self.action_by){
        self.submit()
        console.log('Continue')
      }else{
        self.staff_code = ''
        setTimeout(function () {
          document.getElementById('confirmModal_open').click()
          setTimeout(function () {
            document.getElementById('staff_code').focus()
          }, 500)
        }, 300)
      }
    },
    role_check(role) {
      const self = this
      if (self.role.includes(role)) {
        return true
      } else {
        return false
      }
    },
    staff_code_check() {
      const self = this
      if (self.check_staff_code && self.staff_code) {
        this.axios
          .get('staff/vaild/' + self.staff_code, {})
          .then(function (response) {
            var staff_info = response.data.data
            if(response.data.check_ip == false){
              self.allowed = true
            }
            if(self.staff_code_checked == false){
              self.staff_id = ''
              self.role = []
              if (staff_info.length > 0) {
                self.staff_id = staff_info[0]['id']
                self.role = response.data.role
                var view_role = self.role.findIndex(el => el == '15')
                var ip_role = self.role.findIndex(el => el == '20')
                if(view_role >= 0){
                  if(self.allowed == true || ip_role >= 0){
                    self.vaild_staff_code = true
                    self.permission = response.data.permission
                    var index = self.role.findIndex(el => el == '14')
                    if(index >= 0){
                      self.staff = 'all'
                    }else{
                      self.staff = self.staff_id
                    }
                    document.getElementById('confirmModal_close').click()
                    self.staff_code_checked = true
                    self.show = true
                    self.initialize()
                  }else{
                    self.vaild_staff_code = false
                  }
                }else{
                  self.vaild_staff_code = false
                }
              }else {
                self.vaild_staff_code = false
              }
            }else{
              if (staff_info.length > 0) {
                self.action_by = staff_info[0]['id']
                document.getElementById('confirmModal_close').click()
                if(self.action_type == 'submit' || self.action_type == 'drag_event' || self.action_type == 'submit_continue'){
                  self.submit()
                }else if(self.action_type == 'checkin'){
                  self.checkin()
                }else if(self.action_type == 'checkout'){
                  self.checkout()
                }else if(self.action_type == 'confirm_delete'){
                  self.confirm_delete()
                }else if(self.action_type == 'therapist_checkin'){
                  self.eventInfo.without_therapist = "0"
                  self.submit()
                }else if(self.action_type == 'request'){
                  self.update_request()
                }
                self.vaild_staff_code = true
              }else {
                self.vaild_staff_code = false
              }
            }
          })
        document.getElementById('staff_code').blur()
      }
    },
    before_booking_check(){
      const self = this
      if(self.member_no != ''){
      //POS API
        this.axios({
        method: 'post',
        url: 'https://record.ankh.com.hk/api/pos/v1/fetch/member',
        data: {
          find: self.member_no
        },
        headers: {
          'Authorization': `Bearer 68|003iXlulhk5hFHGUHVMyEenUbGxCXPAVDVNJIw38`,
          'X-Requested-With': `XMLHttpRequest`
        },
        withCredentials: true,
        }).then(function (response) {
          if(response.data.result == "success"){
            self.member_no = response.data.member.memberId
          }
          self.booking_check()
        })
      }
    },
    booking_check(){
      const self = this
      self.history_today = true
      if(self.member_no != ''){
        this.formData = new FormData()
        this.formData.append('branch', self.branch)
        this.formData.append('member_no', self.member_no)
        this.axios
        .post('/booking/history', this.formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(function (response) {
          self.booking_history = response.data.data
          if(self.booking_history.length > 0 ){
            self.booking_history.forEach((item) => {
              if(item.member_no){
                //self.member_check(item)
              }
              var BDate = new Date(item.created_at)
              item.created_at = BDate.getFullYear() + '-' +('0' + (BDate.getMonth()+1)).slice(-2)+ '-' +  ('0' + BDate.getDate()).slice(-2) + ' '+('0' + (BDate.getHours())).slice(-2)+ ':'+('0' + (BDate.getMinutes())).slice(-2)+ ':'+('0' + (BDate.getSeconds())).slice(-2)
              if(item.history.length > 0){
                item.history.forEach((history) => {
                  var HDate = new Date(history.created_at)
                  history.created_at = HDate.getFullYear() + '-' +('0' + (HDate.getMonth()+1)).slice(-2)+ '-' +  ('0' + HDate.getDate()).slice(-2) + ' '+('0' + (HDate.getHours())).slice(-2)+ ':'+('0' + (HDate.getMinutes())).slice(-2)+ ':'+('0' + (HDate.getSeconds())).slice(-2)
                })
              }
              item.bookingDate = item.booking_date
              item.startDate = item.bookingDate+'T'+item.start_time+':00'
              item.endDate = item.bookingDate+'T'+item.end_time+':00'
              item.bookingStartTime = {
                hours: item.start_time.substring(0, 2),
                minutes: item.start_time.substring(3, 5),
                seconds: '00'
              }
              item.bookingEndTime = {
                hours: item.end_time.substring(0, 2),
                minutes: item.end_time.substring(3, 5),
                seconds: '00'
              }
              if(item.arrival_time){
                item.arrivalTime = {
                  hours: item.arrival_time.substring(0, 2),
                  minutes: item.arrival_time.substring(3, 5),
                  seconds: '00'
                }
              }else{
                item.arrivalTime = {
                  hours: '00',
                  minutes: '00',
                  seconds: '0'
                }
              }

              // Advisor array
              var advisor = []
              if(item.advisor){
                var advisor_re = item.advisor
                advisor_re = advisor_re.replace('[', '')
                advisor_re = advisor_re.replace(']', '')
                advisor_re = advisor_re.replace('"', '')
                advisor = advisor_re.split(',')
              }
              item.advisor = advisor

              // Chinese Medicine array
              var chinese_medicine = []
              if(item.chinese_medicine){
                var chinese_medicine_re = item.chinese_medicine
                chinese_medicine_re = chinese_medicine_re.replace('[', '')
                chinese_medicine_re = chinese_medicine_re.replace(']', '')
                chinese_medicine_re = chinese_medicine_re.replace('"', '')
                chinese_medicine = chinese_medicine_re.split(',')
              }
              item.chinese_medicine = chinese_medicine

              // Nutritionist array
              var nutritionist = []
              if(item.nutritionist){
                var nutritionist_re = item.nutritionist
                nutritionist_re = nutritionist_re.replace('[', '')
                nutritionist_re = nutritionist_re.replace(']', '')
                nutritionist_re = nutritionist_re.replace('"', '')
                nutritionist = nutritionist_re.split(',')
              }
              item.nutritionist = nutritionist

              // Sports Science Consultant array
              /*
              var sports_science_consultant = []
              if(item.sports_science_consultant){
                var sports_science_consultant_re = item.sports_science_consultant
                sports_science_consultant_re = sports_science_consultant_re.replace('[', '')
                sports_science_consultant_re = sports_science_consultant_re.replace(']', '')
                sports_science_consultant_re = sports_science_consultant_re.replace('"', '')
                sports_science_consultant = sports_science_consultant_re.split(',')
              }
              item.sports_science_consultant = sports_science_consultant
              */

              // Machine array
              var machine = []
              if(item.machine){
                var machine_re = item.machine
                machine_re = machine_re.replace('[', '')
                machine_re = machine_re.replace(']', '')
                machine_re = machine_re.replace('"', '')
                machine = machine_re.split(',')
              }
              item.machine = machine
            })
          }
          document.getElementById('searchbookingbutton').click()
        })
      }
    },
    change_bookingStartTime(){
      const self = this
      if(self.eventInfo.bookingStartTime.minutes == "30"){
        self.eventInfo.bookingEndTime.hours = self.eventInfo.bookingStartTime.hours + 1
        self.eventInfo.bookingEndTime.minutes = "00"
      }else{
        self.eventInfo.bookingEndTime.hours = self.eventInfo.bookingStartTime.hours
        self.eventInfo.bookingEndTime.minutes = "30"
      }
      self.eventInfo.start_time = self.eventInfo.bookingStartTime.hours.toString().padStart(2, '0')+":"+self.eventInfo.bookingStartTime.minutes.toString().padStart(2, '0')
      self.eventInfo.end_time = self.eventInfo.bookingEndTime.hours.toString().padStart(2, '0')+":"+self.eventInfo.bookingEndTime.minutes.toString().padStart(2, '0')
      self.room_check()
      self.therapist_check()
    },
    change_bookingEndTime(){
      const self = this
      if((self.eventInfo.bookingStartTime.hours > self.eventInfo.bookingEndTime.hours) || (self.eventInfo.bookingStartTime.hours == self.eventInfo.bookingEndTime.hours && self.eventInfo.bookingStartTime.minutes > self.eventInfo.bookingEndTime.minutes)){
        self.eventInfo.bookingEndTime.hours = self.eventInfo.bookingStartTime.hours + 1
        self.eventInfo.end_time = self.eventInfo.bookingEndTime.hours.toString().padStart(2, '0')+":"+self.eventInfo.bookingEndTime.minutes.toString().padStart(2, '0')
      }else{
        //self.eventInfo.bookingEndTime.hours = self.eventInfo.bookingStartTime.hours + 1
        self.eventInfo.end_time = self.eventInfo.bookingEndTime.hours.toString().padStart(2, '0')+":"+self.eventInfo.bookingEndTime.minutes.toString().padStart(2, '0')
      }
      self.room_check()
      self.therapist_check()
    },
    change_bookingDate(info){
      const self = this
      var old_therapist = info.org_therapist
      info.org_therapist = []
      if (info.bookingDate.length != 10) {
        var fd_offset = info.bookingDate.getTimezoneOffset()
        var date = new Date(info.bookingDate.getTime() - fd_offset * 60 * 1000)
        info.bookingDate = date.toISOString().split('T')[0]
      }
      self.therapist_list = [
        {
          name: '舊客Waiting List',
          nickname: '舊客Waiting List',
          text: '舊客Waiting List',
          id: 9999,
          color: '#ededed',
        },
        {
          name: '新客Waiting List',
          nickname: '新客Waiting List',
          text: '新客Waiting List',
          id: 99999,
          color: '#ededed',
        }
      ]
      this.formData = new FormData()
      this.formData.append('staff_id', self.staff)
      this.formData.append('start_booking_date', info.bookingDate)
      this.formData.append('end_booking_date', info.bookingDate)
      this.formData.append('branch', self.branch)
      this.axios
      .post('/booking/master', this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        if(response.data.roster_list.length > 0 ){
          response.data.roster_list.forEach((item) => {
            item.text = item.nickname
            item.name = item.nickname
            item.color = '#ededed'
            self.therapist_list.push(item)
          })
          old_therapist.forEach((item) => {
            var index = self.therapist_list.findIndex(el => el.id == item)
            if(index >= 0){
              info.org_therapist.push(item)
            }
          })
        }
        self.bookingDatedataSource = response.data.data
        self.room_check()
        self.therapist_check()
      })
    },
    home_page(){
      document.getElementById('confirmModal_close').click()
    },
  },
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style>
@media screen and (max-width: 1080px) {
  .body {
    font-size: 16px !important;
  }
}

.modal {
  top: -20px !important;
}

.disable-date {
  background-image: repeating-linear-gradient( 135deg, rgba(244, 67, 54, 0.2), rgba(244, 67, 54, 0.2) 4px, transparent 4px, transparent 9px );
}

.genderM {
  border-left: 10px solid #a9e7f3 !important;
  border-top: 10px solid #a9e7f3 !important;
  border-bottom: 10px solid #a9e7f3 !important;
}

.genderF {
  border-left: 10px solid #ffd5df !important;
  border-top: 10px solid #ffd5df !important;
  border-bottom: 10px solid #ffd5df !important;
}

.genderMBG{
  background-color: #a9e7f3 !important;
}

.genderFBG{
  background-color: #ffd5df !important;
}

.newJoin {
  background-color: #ebeb38 !important;
}

.CallTeam {
  background-color: #50d43a;
}

.WTSTeam {
  background-color: #ff9f55;
}

.vtl-tbody-td{
  padding: 0.3rem !important;
}

.fa-whatsapp  {
  color:#fff;
  background:
   linear-gradient(#25d366,#25d366) 14% 84%/16% 16% no-repeat,
   radial-gradient(#25d366 60%,transparent 0);
}

.fa-phone  {
  color: rgb(4, 97, 66);
}

.fa-star  {
  color: #c9c930;
}

.drag {
  box-sizing: border-box;
  padding: 3px 5px;
  margin-bottom: 5px;
}

.offcanvas-width{
  width: 550px !important;
}

.offcanvas-width2{
  width: 700px !important;
}

.offcanvas-width3{
  width: 250px !important;
}

.offcanvas-width4{
  width: 550px !important;
}

@keyframes fade {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1.0;
  }
}
@-webkit-keyframes fade {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1.0;
  }
}
.without_therapist {
  color: red;
  animation: fade 600ms infinite;
  -webkit-animation: fade 600ms infinite;
}

.need_update {
  color: red;
  animation: fade 600ms infinite;
  -webkit-animation: fade 600ms infinite;
}

.need_update2 {
  color: white;
  animation: fade 600ms infinite;
  -webkit-animation: fade 600ms infinite;
}

.tab-content {
  padding: 0px !important; 
}

.nav-link {
  color: #525c6a;
}

.nav-tabs {
  border-bottom: 0px solid;
}

.multiselect-dropdown {
  height: 25rem !important;
}

.offcanvas-start {
  z-index: 9999 !important;
}

.vtl-table {
  max-height: calc(100vh - 250px) !important;
}
</style>