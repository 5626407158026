<template>
  <CToaster placement="top-end">
    <CToast :autohide="false" :class="toast.cus_class" v-for="toast in toasts" :key="toast.content">
      <CToastHeader closeButton>
        <svg
          class="rounded me-2"
          width="20"
          height="20"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
          role="img"
        >
          <rect width="100%" height="100%" fill="#41c847"></rect>
        </svg>
        <span class="me-auto fw-bold">{{ toast.data.nickname }}</span>
      </CToastHeader>
      <CToastBody><span class="me-auto fw-bold" style="color:#404345;">{{ toast.content }}</span></CToastBody>
    </CToast>
  </CToaster>
  <!-- Modal for Authorization-->
  <div>
    <b-button
      id="confirmModal_open"
      variant="outline-success"
      style="display: none"
      data-bs-toggle="modal"
      data-bs-target="#confirmModal"
      >{{ $t('common.button.confirm') }}</b-button
    >
  </div>
  <div
    id="confirmModal"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="confirmModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="confirmModalLabel" class="modal-title"></h5>
          <button
            id="confirmModal_close"
            type="button"
            style="display: none"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <span>{{ $t('common.master.pass_code') }}</span>
              <CFormInput
                id="staff_code"
                v-model="staff_code"
                size="sm"
                type="password"
                @keyup.enter="staff_code_check()"
              />
            </div>
          </div>
          <div v-if="!vaild_staff_code && !staff_id && allowed" class="row">
            <div class="col-12">
              <span class="text-danger">{{
                $t('common.message.staff_code_error')
              }}</span>
            </div>
          </div>
          <div v-if="!vaild_staff_code && staff_id">
            <div class="row">
              <div class="col-12">
                <span class="text-danger">{{
                  $t('common.message.permission_denied')
                }}</span>
              </div>
            </div>
          </div>
          <div v-if="!vaild_staff_code && !allowed" class="row">
            <div class="col-12">
              <h4 style="color: red;">請使用公司網絡!!!</h4>
            </div>
          </div>
          <div class="row" v-if="staff_code_checked == false">
            <div class="col-12">
              輸入後按Enter/提交繼續
            </div>
          </div>
          <div>
            <b-button variant="outline-success" @click="staff_code_check()">{{
              $t('common.button.submit')
            }}</b-button>
          </div>
        </div>
        <div class="modal-footer" style="display: none">
          <b-button variant="outline-success" @click="submit()">{{
            $t('common.button.submit')
          }}</b-button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Authorization-->
  <b-overlay :show="show" rounded="sm">
    <div class="row">
      <div class="col-4 text-left">
        <b-button style="background-color: white !important; border-radius: 2rem !important; color:rgb(4, 97, 66) !important;" href="./#/Dashboard" class="mx-1" variant="outline-light">
          <font-awesome-icon :icon="['fas', 'house-user']" size="2x"/>
        </b-button>
      </div>
      <div class="col-4">
        <div class="flex-row justify-content-between">
          <div>
            <h1 style="color:green;">設定員工權限</h1>
          </div>
        </div>
      </div>
      <div class="col-4 text-right">
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-6 text-right">
        <Multiselect
          v-model="filtersourceList.branch_id"
          mode="single"
          :close-on-select="true"
          :searchable="false"
          :create-option="false"
          :options="branch_list"
          :allow-empty="false"
          :canClear="true"
          valueProp="id"
          trackBy="branch"
          label="branch"
          placeholder="選擇分店"
        />
      </div>
      <div class="col-6 text-right">
        <CFormInput
          v-model="filtersourceList.freetext"
          type="text"
          placeholder="員工名稱"
        />
      </div>
    </div>
    <br>
    <div v-for="item of dataSourceList" class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            {{ item.nickname }} ({{ getlistitem('shop', item.shop) }}) - {{ getlistitem('job', item.job_title) }}
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <Multiselect
              v-model="item.role"
              mode="tags"
              :close-on-select="false"
              :searchable="true"
              :create-option="false"
              :options="role_list"
              :allow-empty="false"
              :canClear="false"
              valueProp="id"
              trackBy="role"
              label="role"
              @Close="update_api(item)"
              @Open="before_update(item)"
            />
          </div>
        </div>
        <br>
        <hr>
        <br>
      </div>
    </div>
    <!--
    <div class="row">
      <div class="col-12">
        <table-lite
          :is-slot-mode="true"
          :columns="getField"
          :rows="dataSourceList"
          :is-hide-paging="true"
        >
          <template #user="data">
            {{ data.value.nickname }}
          </template>
          <template #branch="data">
            {{ getlistitem('shop', data.value.shop) }}
          </template>
          <template #position="data">
            {{ getlistitem('job', data.value.job_title) }}
          </template>
          <template #active_system="data">
            <div class="row">
              <div class="col-5 text-left">
              </div>
              <div class="col-3 text-center">
                <b-form-checkbox
                    v-model="data.value.active_system"
                    value="1"
                    unchecked-value="0"
                    @click="role_edit(data.value)"
                  >
                </b-form-checkbox>
              </div>
              <div class="col-4 text-left">
              </div>
            </div>
          </template>
          <template #role="data">
            <Multiselect
              v-model="data.value.role"
              mode="tags"
              :close-on-select="false"
              :searchable="true"
              :create-option="false"
              :options="role_list"
              :allow-empty="false"
              :canClear="false"
              valueProp="id"
              trackBy="role"
              label="role"
              @Close="update_api(data.value)"
              @Open="before_update(data.value)"
            />
            {{ getlistitem('role', data.value.role) }}
          </template>
        </table-lite>
      </div>
    </div>
    -->
    <br>
    <div v-if="staff_id && role_check(21)">
      <div class="row text-left">
        <div class="col-12">
          <span style="color:red;">權限說明</span>
        </div>
      </div>
      <div class="row text-left">
        <div class="col-6">
          <span>使用系統: {{ getlistitem('role', 15) }}</span>
        </div>
        <div class="col-6">
          <span>新增/修改Booking: {{ getlistitem('role', 14) }}</span>
        </div>
      </div>
      <hr>
      <div class="row text-left">
        <div class="col-6">
          <span>Booking記錄: {{ getlistitem('role', 17) }}</span>
        </div>
        <div class="col-6">
          <span>控制Roster: {{ getlistitem('role', 16) }}</span>
        </div>
      </div>
      <hr>
      <div class="row text-left">
        <div class="col-6">
          <span>可被選擇理療師: {{ getlistitem('role', 4) }}</span>
        </div>
        <div class="col-6">
          <span>可被選擇顧問: {{ getlistitem('role', 3) }}</span>
        </div>
      </div>
      <hr>
      <div class="row text-left">
        <div class="col-6">
          <span>可被選擇中醫: {{ getlistitem('role', 7) }}</span>
        </div>
        <div class="col-6">
          <span>Call Team: {{ getlistitem('role', 19) }}</span>
        </div>
      </div>
    </div>
    <br>
  </b-overlay>
</template>

<script>
export default {
  components: {
  },
  name: 'AssignRole',
  data() {
    return {
      allowed: false,
      staff: '',
      staff_code: '',
      staff_id: '',
      action_by: '',
      action_type: '',
      staff_code_checked: false,
      check_staff_code: true,
      vaild_staff_code: true,
      role: [],
      permission: [],
      show: false,
      sourceList: 'active',
      filtersourceList: {
        freetext: '',
        branch_id: '',
      },
      lang: '',
      branch_list: [],
      job_title_list: [],
      user_role_list: [],
      role_list: [],
      before: [],
      dataSource: [],
      toasts: [],
    }
  },
  computed: {
    dataSourceList: function () {
      return this.get_dataSourceList()
    },
    getField: function () {
      var fields = []
      if(this.role_check(21)){
        fields = [
          {
            label: '員工',
            field: 'user',
            sortable: false,
            isKey: false,
            width: "15%",
          },
          {
            label: '分店',
            field: 'branch',
            sortable: false,
            isKey: false,
            width: "10%",
          },
          {
            label: '職位',
            field: 'position',
            sortable: false,
            isKey: false,
            width: "15%",
          },
          {
            label: '使用Booking System',
            field: 'active_system',
            sortable: false,
            isKey: false,
            width: "15%",
          },
          {
            label: '權限',
            field: 'role',
            sortable: false,
            isKey: false,
            width: "45%",
          },
        ]
      }else{
        fields = [
          {
            label: '員工',
            field: 'user',
            sortable: false,
            isKey: false,
            width: "25%",
          },
          {
            label: '分店',
            field: 'branch',
            sortable: false,
            isKey: false,
            width: "25%",
          },
          {
            label: '職位',
            field: 'position',
            sortable: false,
            isKey: false,
            width: "25%",
          },
          {
            label: '使用Booking System',
            field: 'active_system',
            sortable: false,
            isKey: false,
            width: "25%",
          },
        ]
      }
      return fields
    },
  },
  watch: {
  },
  created() {
    const self = this
    self.lang = localStorage.getItem('locale')
    self.getrealip()
  },
  mounted() {
    const self = this
    this.emitter.on('lang_changed', (lang) => {
      self.initialize()
      self.lang = lang
    })
  },
  methods: {
    getrealip(){
      const self = this
      var branch_list = []
      this.axios
        .post('/booking/prepare', this.formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(function (response) {
          branch_list = response.data.branch_list
          self.axios
        .get('https://record.ankh.com.hk/api/getip', {})
        .then(function (res) {
          const ip = res.data.ip
          branch_list.forEach((item) => {
              if(item.ip_address == ip || item.ip_address2 == ip){
                self.allowed = true
              }
            })
          setTimeout(function () {
            document.getElementById('confirmModal_open').click()
            setTimeout(function () {
              document.getElementById('staff_code').focus()
            }, 500)
          }, 300)
        })
        .catch((error) => { 
          console.error(error)
          self.allowed = true
          setTimeout(function () {
            document.getElementById('confirmModal_open').click()
            setTimeout(function () {
              document.getElementById('staff_code').focus()
            }, 500)
          }, 300)
        })
      })
    },
    initialize() {
      const self = this
      this.formData = new FormData()
      this.axios
      .post('/booking/prepare', this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        self.branch_list = response.data.branch_list
        self.dataSource = response.data.staff_list.filter(function (item) {
          return item.re_sign == 0 && item.active == 1 && item.status == 1
        })
        self.job_title_list = response.data.job_title_list
        self.user_role_list = response.data.user_role_list
        self.role_list = response.data.role_list
        self.dataSource.forEach((user) => {
          user.active_system = '0'
          user.role = []
          var find_role = self.user_role_list.filter(function (item) {
            return item.parent_id == user.id && item.status == 1
          })
          find_role.forEach((role) => {
            user.role.push(role.role)
            user.active_system = '1'
          })
        })
        setTimeout(function () {
          self.get_api()
        }, 500)
      })
    },
    get_api() {
      const self = this
      self.show = false
    },
    role_check(role) {
      const self = this
      if (self.role.includes(role)) {
        return true
      } else {
        return false
      }
    },
    staff_code_check() {
      const self = this
      if (self.check_staff_code && self.staff_code) {
        this.axios
          .get('staff/vaild/' + self.staff_code, {})
          .then(function (response) {
            var staff_info = response.data.data
            if(response.data.check_ip == false){
              self.allowed = true
            }
            if(self.staff_code_checked == false){
              self.staff_id = ''
              self.role = []
              if (staff_info.length > 0) {
                self.staff_id = staff_info[0]['id']
                self.role = response.data.role
                if(self.role_check(21) || self.role_check(22)){
                  if(self.allowed == true || self.role_check(20)){
                    self.vaild_staff_code = true
                    self.permission = response.data.permission
                    var index = self.role.findIndex(el => el == '14')
                    if(index >= 0){
                      self.staff = 'all'
                    }else{
                      self.staff = self.staff_id
                    }
                    document.getElementById('confirmModal_close').click()
                    self.staff_code_checked = true
                    self.show = true
                    self.initialize()
                  }else{
                    self.vaild_staff_code = false
                  }
                }else{
                  self.vaild_staff_code = false
                }
              }else {
                self.vaild_staff_code = false
              }
            }
          })
        document.getElementById('staff_code').blur()
      }
    },
    role_edit(data){
      const self = this
      setTimeout(function () {
        if(data.active_system == '1'){
          if(data.job_title == '45' || data.job_title == '46' || data.job_title == '50'){
            data.role = [4,15,20]
          }else if(data.job_title == '47' || data.job_title == '57'){
            data.role = [3,14,15]
          }else if(data.job_title == '49' || data.job_title == '51'){
            data.role = [14,15,16,17]
          }else if(data.job_title == '13'){
            data.role = [14,18,19]
          }else{
            data.role = [15]
          }
        }else{
          data.role = []
        }
        self.update_api(data)
      }, 300)
    },
    before_update(data){
      const self = this
      self.before = JSON.parse(JSON.stringify(data))
    },
    update_api(data){
      const self = this
      const old_shop = data.shop
      if(JSON.stringify(self.before.role) != JSON.stringify(data.role)){
        var shop = []
        if(data.shop){
          var shop_re = data.shop
          shop_re = shop_re.replace('[', '')
          shop_re = shop_re.replace(']', '')
          shop_re = shop_re.replace('"', '')
          shop = shop_re.split(',')
        }
        data.shop = shop
        this.axios.post('master/createorupdate', {
          id: data.id,
          dataset: data,
          table: 'staff',
        })
        .then(function () {
          self.toasts.push({
            cus_class: 'toast-success',
            data: data,
            content: '成功更改權限'
          })
          data.shop = old_shop
        })
        .catch(function (error) {
          console.log(error)
          self.toasts.push({
            cus_class: 'toast-fail',
            data: data,
            content: '更新失敗!!!'
          })
        })
      }
    },
    get_dataSourceList() {
      const self = this
      let list = []
      list = this.dataSource
      if(this.filtersourceList.branch_id){
        list = list.filter(function (item) {
          return item?.shop?.includes(self.filtersourceList.branch_id)
        })
      }
      if(this.filtersourceList.freetext){
        list = list.filter(function (item) {
          return item?.english_name?.toLowerCase().includes(self.filtersourceList.freetext.toLowerCase()) || item?.chinese_name?.toLowerCase().includes(self.filtersourceList.freetext.toLowerCase()) || item?.nickname?.toLowerCase().includes(self.filtersourceList.freetext.toLowerCase())
        })
      }
      return list
    },
    getlistitem(list, id) {
      const self = this
      var selected = []
      var name = ""
      if(list == "shop"){
        if(id){
          if(Array.isArray(id) == false){
            id = id.toString().split(",")
          }
          id.forEach((shop, index) => {
            selected = self.branch_list.find(i => i.id == shop)
            if(selected){
              if(index == 0){
                name = selected.branch
              }else{
                name += ', '+selected.branch
              }
            }
          })
          return name
        }
      }else if(list == "role"){
        if(id){
          if(Array.isArray(id) == false){
            id = id.toString().split(",")
          }
          id.forEach((role, index) => {
            selected = self.role_list.find(i => i.id == role)
            if(selected){
              if(index == 0){
                name = selected.role
              }else{
                name += ', '+selected.role
              }
            }
          })
          return name
        }
      }else if(list == "job"){
        if(id){
          if(Array.isArray(id) == false){
            id = id.toString().split(",")
          }
          id.forEach((job, index) => {
            selected = self.job_title_list.find(i => i.id == job)
            if(selected){
              if(index == 0){
                name = selected.name_tc
              }else{
                name += ', '+selected.name_tc
              }
            }
          })
          return name
        }
      }
    },
  },
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style>
@media screen and (max-width: 1080px) {
  .body {
    font-size: 16px !important;
  }
}

.modal {
  top: -20px !important;
}

.vtl-table {
  max-height: calc(100vh - 350px) !important;
}

.multiselect-dropdown {
  height: 25rem !important;
}

.toast {
  font-size: 1.5rem !important;
}
</style>